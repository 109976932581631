"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _MaterialConfigService = _interopRequireDefault(require("@/api/MaterialConfigService"));

var _SelectComponent = _interopRequireDefault(require("@/views/materialManagement/materialConfig/components/SelectComponent"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    selectComponent: _SelectComponent.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modeTypeCode: {
      type: Number,
      required: false
    },
    isDetail: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {
      tabsActiveName: "page",
      modelType: null,
      modelTypeOptions: [],
      selectPicType: 0,
      selectTextType: 1,
      selectPictureList: [],
      selectTextList: [],
      row: {},
      txt: 'dsfsdjkfsSSS-DDS,HDSD,#233223,14pt,12px'
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  watch: {},
  methods: {
    // 获取下拉列表内容  文本为1 图片为0
    getSelectList: function getSelectList() {
      var _this = this;

      var picParam = {
        childType: this.selectPicType
      }; // 图片

      _MaterialConfigService.default.getSelectList(picParam).then(function (result) {
        _this.selectPictureList = result.data;
      });

      var textParam = {
        childType: this.selectTextType
      }; // 文本

      _MaterialConfigService.default.getSelectList(textParam).then(function (result) {
        _this.selectTextList = result.data;
      });
    },
    handleCancel: function handleCancel() {
      // this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    getModelData: function getModelData() {
      var _this2 = this;

      _MaterialConfigService.default.getModelList().then(function (result) {
        _this2.modelTypeOptions = [];
        result.data.forEach(function (item) {
          var obj = {
            dictValue: Number(item.id),
            dictLabel: item.text
          };

          _this2.modelTypeOptions.push(obj);
        });

        if (_this2.modeTypeCode) {
          _this2.modelType = _this2.modeTypeCode;
        }
      });
    },
    // 选项框选中回调
    setSelectr1Value: function setSelectr1Value(val) {
      this.row.r1 = val;
    },
    setSelectr2Value: function setSelectr2Value(val) {
      this.row.r2 = val;
    },
    setSelectr3Value: function setSelectr3Value(val) {
      this.row.r3 = val;
    },
    setSelectr4Value: function setSelectr4Value(val) {
      this.row.r4 = val;
    },
    setSelectr5Value: function setSelectr5Value(val) {
      this.row.r5 = val;
    },
    setSelectr6Value: function setSelectr6Value(val) {
      this.row.r6 = val;
    },
    setSelectr7Value: function setSelectr7Value(val) {
      this.row.r7 = val;
    },
    setSelectr8Value: function setSelectr8Value(val) {
      this.row.r8 = val;
    },
    setSelectr9Value: function setSelectr9Value(val) {
      this.row.r9 = val;
    },
    setSelectr10Value: function setSelectr10Value(val) {
      this.row.r10 = val;
    },
    setSelectr11Value: function setSelectr11Value(val) {
      this.row.r11 = val;
    },
    setSelectr12Value: function setSelectr12Value(val) {
      this.row.r12 = val;
    },
    setSelectr13Value: function setSelectr13Value(val) {
      this.row.r13 = val;
    },
    setSelectr14Value: function setSelectr14Value(val) {
      this.row.r14 = val;
    },
    setSelectr15Value: function setSelectr15Value(val) {
      this.row.r15 = val;
    },
    setSelectr16Value: function setSelectr16Value(val) {
      this.row.r16 = val;
    },
    setSelectr17Value: function setSelectr17Value(val) {
      this.row.r17 = val;
    },
    setSelectr18Value: function setSelectr18Value(val) {
      this.row.r18 = val;
    },
    setSelectr19Value: function setSelectr19Value(val) {
      this.row.r19 = val;
    },
    setSelectr20Value: function setSelectr20Value(val) {
      this.row.r20 = val;
    },
    setSelectr21Value: function setSelectr21Value(val) {
      this.row.r21 = val;
    },
    setSelectr22Value: function setSelectr22Value(val) {
      this.row.r22 = val;
    },
    setSelectr23Value: function setSelectr23Value(val) {
      this.row.r23 = val;
    },
    setSelectr24Value: function setSelectr24Value(val) {
      this.row.r24 = val;
    },
    setSelectr25Value: function setSelectr25Value(val) {
      this.row.r25 = val;
    },
    setSelectr26Value: function setSelectr26Value(val) {
      this.row.r26 = val;
    },
    setSelectr27Value: function setSelectr27Value(val) {
      this.row.r27 = val;
    },
    setSelectr28Value: function setSelectr28Value(val) {
      this.row.r28 = val;
    },
    setSelectr29Value: function setSelectr29Value(val) {
      this.row.r29 = val;
    },
    setSelectr30Value: function setSelectr30Value(val) {
      this.row.r30 = val;
    },
    setSelectr31Value: function setSelectr31Value(val) {
      this.row.r31 = val;
    },
    setSelectr32Value: function setSelectr32Value(val) {
      this.row.r32 = val;
    },
    setSelectr33Value: function setSelectr33Value(val) {
      this.row.r33 = val;
    },
    setSelectr34Value: function setSelectr34Value(val) {
      this.row.r34 = val;
    },
    setSelectr35Value: function setSelectr35Value(val) {
      this.row.r35 = val;
    },
    setSelectr36Value: function setSelectr36Value(val) {
      this.row.r36 = val;
    },
    setSelectr37Value: function setSelectr37Value(val) {
      this.row.r37 = val;
    },
    setSelectr38Value: function setSelectr38Value(val) {
      this.row.r38 = val;
    },
    setSelectr39Value: function setSelectr39Value(val) {
      this.row.r39 = val;
    },
    setSelectr40Value: function setSelectr40Value(val) {
      this.row.r40 = val;
    },
    setSelectr41Value: function setSelectr41Value(val) {
      this.row.r41 = val;
    },
    setSelectr42Value: function setSelectr42Value(val) {
      this.row.r42 = val;
    },
    setSelectr43Value: function setSelectr43Value(val) {
      this.row.r43 = val;
    },
    setSelectr44Value: function setSelectr44Value(val) {
      this.row.r44 = val;
    },
    setSelectr45Value: function setSelectr45Value(val) {
      this.row.r45 = val;
    },
    setSelectr46Value: function setSelectr46Value(val) {
      this.row.r46 = val;
    },
    setSelectr47Value: function setSelectr47Value(val) {
      this.row.r47 = val;
    },
    setSelectr51Value: function setSelectr51Value(val) {
      this.row.r51 = val;
    },
    setSelectr52Value: function setSelectr52Value(val) {
      this.row.r52 = val;
    },
    setSelectr53Value: function setSelectr53Value(val) {
      this.row.r53 = val;
    },
    setSelectr54Value: function setSelectr54Value(val) {
      this.row.r54 = val;
    },
    setSelectr55Value: function setSelectr55Value(val) {
      this.row.r55 = val;
    },
    setSelectr56Value: function setSelectr56Value(val) {
      this.row.r56 = val;
    },
    setSelectr57Value: function setSelectr57Value(val) {
      this.row.r57 = val;
    },
    setSelectr58Value: function setSelectr58Value(val) {
      this.row.r58 = val;
    },
    setSelectr59Value: function setSelectr59Value(val) {
      this.row.r59 = val;
    },
    setSelectr60Value: function setSelectr60Value(val) {
      this.row.r60 = val;
    },
    setSelectr61Value: function setSelectr61Value(val) {
      this.row.r61 = val;
    },
    setSelectr62Value: function setSelectr62Value(val) {
      this.row.r62 = val;
    },
    setSelectr63Value: function setSelectr63Value(val) {
      this.row.r63 = val;
    },
    setSelectr64Value: function setSelectr64Value(val) {
      this.row.r64 = val;
    },
    setSelectr65Value: function setSelectr65Value(val) {
      this.row.r65 = val;
    },
    setSelectr66Value: function setSelectr66Value(val) {
      this.row.r66 = val;
    },
    setSelectr67Value: function setSelectr67Value(val) {
      this.row.r67 = val;
    },
    setSelectr68Value: function setSelectr68Value(val) {
      this.row.r68 = val;
    },
    setSelectr69Value: function setSelectr69Value(val) {
      this.row.r69 = val;
    },
    setSelectr70Value: function setSelectr70Value(val) {
      this.row.r70 = val;
    },
    setSelectr71Value: function setSelectr71Value(val) {
      this.row.r71 = val;
    },
    // 模版与新增编辑区别点
    // 获取模版详情
    getModelDetail: function getModelDetail(type) {
      var _this3 = this;

      var postParam = {
        tempType: type
      };

      _MaterialConfigService.default.getModelDetail(postParam).then(function (result) {
        result.hasFailure(function () {
          _this3.$message.warning(result.getErrorMessage("模板查询失败"));
        }).hasSuccess(function () {
          var resDetail = result.data;
          resDetail.forEach(function (item) {
            var num = item.fieldName.split('_')[1];

            _this3.$set(_this3.row, 'r' + num, item.fileUuid);
          });
        });
      });
    },
    modelTypeChange: function modelTypeChange() {
      this.getModelDetail(this.modelType);
    }
  },
  created: function created() {
    this.getModelData();
    this.getSelectList();

    if (this.modeTypeCode) {
      this.getModelDetail(this.modeTypeCode);
    }
  }
};
exports.default = _default2;