"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ApplicationManagentService = _interopRequireDefault(require("@/api/ApplicationManagentService"));

var _FileUpload = _interopRequireDefault(require("@/components/FileUpload"));

var _Enums = require("@/api/Enums");

var _FileUploadPicture = _interopRequireDefault(require("@/components/FileUploadPicture"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    FileUpload: _FileUpload.default,
    FileUploadPicture: _FileUploadPicture.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      rules: {},
      selectedRow: {
        taskPicture: undefined
      },
      FileUploadUrl: _Enums.FileUploadUrl
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      this.btnSaveLoading = false;
      this.$message.success('保存成功');
      this.$emit('ok', this.selectedRow);
      this.handleCancel();
    },
    // 时间格式化
    formatTime: function formatTime(t) {
      var data = t;
      var dtime = new Date(data);
      var year = dtime.getFullYear();
      var month = dtime.getMonth() + 1;

      if (month < 10) {
        month = '0' + month;
      }

      var day = dtime.getDate();

      if (day < 10) {
        day = '0' + day;
      }

      return year + '-' + month + '-' + day;
    },
    reviewFileChange: function reviewFileChange(item) {
      if (item instanceof Array && item.length > 0) {
        this.selectedRow.taskPicture = {
          name: item[0].name,
          uname: item[0].uname,
          url: item[0].url,
          secUuid: item[0].secUuid
        };
      } else {
        this.selectedRow.taskPicture = undefined;
      }
    }
  },
  created: function created() {
    // 编辑反显
    if (this.row) {
      this.selectedRow = this.row;
    }
  }
};
exports.default = _default;