"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _uniqueId = _interopRequireDefault(require("lodash/uniqueId"));

var _charts = _interopRequireDefault(require("@/components/Charts/mixin/charts"));

//
//
//
//
//
//
var _default = {
  mixins: [_charts.default],
  props: {
    data: {
      type: Array,
      default: null
    }
  },
  data: function data() {
    return {
      timepick: '',
      charts: {
        chart: {
          id: (0, _uniqueId.default)('chart_'),
          instance: null,
          // charts mixin 时会通过混入的方式初始化 echarts 实例到这个属性
          option: {
            tooltip: {
              trigger: 'item',
              formatter: function formatter(value) {
                return "".concat(value.data.name, ": ").concat(value.data.value, "\u4EBA(").concat(value.data.percentage, "%)");
              }
            },
            series: [{
              type: 'funnel',
              left: '1%',
              right: '1%',
              bottom: '3%',
              top: '10%',
              // containLabel: true,
              gap: 0,
              label: {
                show: true,
                position: 'inside',
                formatter: function formatter(value) {
                  return "".concat(value.data.name, "\n").concat(value.data.value, "\u4EBA\n(").concat(value.data.percentage, "%)");
                } // formatter: '{b}\n{c}人\n({d}%)',

              },
              sort: 'none',
              data: this.data,
              itemStyle: {
                normal: {
                  color: function color(colors) {
                    var colorList = ['#AFC8F9', '#6D99EE', '#2E63C7', '#032A73'];
                    return colorList[colors.dataIndex];
                  }
                }
              }
            }]
          }
        }
      }
    };
  }
};
exports.default = _default;