"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLoginToken = getLoginToken;
exports.setLoginToken = setLoginToken;
exports.removeLoginToken = removeLoginToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var LoginToken = 'login_token';

function getLoginToken() {
  return _jsCookie.default.get(LoginToken);
}

function setLoginToken(loginToken) {
  _jsCookie.default.set(LoginToken, loginToken);
}

function removeLoginToken() {
  _jsCookie.default.remove(LoginToken);
}