"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ActivityManager = _interopRequireDefault(require("@/api/ActivityManager"));

var _CommonService = _interopRequireDefault(require("@/api/CommonService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    var _this = this;

    var validateName = function validateName(rule, value, callback) {
      if (!_this.selectedRow.congratulations || !_this.selectedRow.cusInfoType || !(_this.selectedRow.videoUploadInfoDTO && _this.selectedRow.videoUploadInfoDTO.videoUrl)) {
        if (_this.selectedRow.cusInfoType == 0 && _this.selectedRow.congratulations && _this.selectedRow.videoUploadInfoDTO && _this.selectedRow.videoUploadInfoDTO.videoUrl) {
          callback();
        } else {
          callback(new Error("请输入广播内容"));
        }
      } else {
        callback();
      }
    };

    return {
      btnSaveLoading: false,
      rules: {
        status: [{
          required: true,
          message: '请选择是否启动广播功能',
          trigger: 'change'
        }],
        gbnr: [{
          required: true,
          validator: validateName,
          trigger: 'change'
        }],
        cusInfoMask: [{
          required: true,
          message: '请选择是否客户信息脱敏',
          trigger: 'change'
        }],
        // playDuration: [{ required: true, message: '请输入播放时长', trigger: 'blur' }],
        playInterval: [{
          required: true,
          message: '请输入播放间隔',
          trigger: 'blur'
        }],
        playOrder: [{
          required: true,
          message: '请选择播放顺序',
          trigger: 'change'
        }] // taskTitle: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        // taskType: [{ required: true, message: '请选择任务类型', trigger: 'change' }],
        // calculateType: [{ required: true, message: '请选择计算方式', trigger: 'change' }],
        // baseTime: [{ required: true, message: '请选择基准时间', trigger: 'change' }],
        // isShow: [{ required: true, message: '请选择是否', trigger: 'change' }],
        // gbnr: [{ required: true, validator: validateName, trigger: 'change' }],
        // promoteAmount: [{ required: true, message: '请输入提升金额', trigger: 'blur' }]

      },
      selectedRow: {
        status: 0,
        cusInfoMask: 1
      },
      informationTypeOptions: [// {dictValue: 0,dictLabel: '姓名'},
        // {dictValue: 1,dictLabel: '电话'},
      ],
      playOrderOptions: [// {dictValue: 0,dictLabel: '随机'},
        // {dictValue: 1,dictLabel: '顺序'},
      ],
      ruleFile: []
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this2 = this;

      this.selectedRow.activityId = this.row.secUuid;
      this.$refs.rowForm.validate(function (valid) {
        if (valid) {
          // const formData = JSON.parse(JSON.stringify(this.selectedRow));
          // const postParam = {
          //   activityBroadcastConfigSaveReqDTO: formData
          // }
          var postParam = JSON.parse(JSON.stringify(_this2.selectedRow));

          _ActivityManager.default.broadcastEdit(postParam).then(function (result) {
            result.hasFailure(function () {
              _this2.btnSaveLoading = false;

              _this2.$message.warning(result.getErrorMessage('保存失败'));
            }).hasSuccess(function () {
              _this2.btnSaveLoading = false;

              _this2.$message.success('保存成功');

              _this2.handleCancel();

              _this2.$emit('ok');
            });
          });
        } else {
          return false;
        }
      });
    },
    getBroadcastData: function getBroadcastData() {
      var _this3 = this;

      // 查询广播配置详情
      var postParam = {
        activityId: this.row.secUuid
      };

      _ActivityManager.default.getBroadcastDetail(postParam).then(function (res) {
        if (res.data && res.data.length) {
          // const resData = res.data[0];
          _this3.selectedRow = res.data[0];

          if (res.data[0].videoUploadInfoDTO.videoUrl) {
            _this3.ruleFile = [{
              name: res.data[0].videoUploadInfoDTO.videoUrl.name
            }];
          } else {
            _this3.ruleFile = [];
          }
        } else {
          _this3.selectedRow = {
            status: 0,
            cusInfoMask: 1
          };
        } // const userSelVo = parentRes.data[0].userSelVo || null;
        // if(userSelVo){
        //   if(userSelVo.cusGroupUuids) {
        //     userSelVo.cusGroupUuids = JSON.parse(userSelVo.cusGroupUuids);
        //   }
        //   this.selectedRow = userSelVo
        // }

      });
    },
    // 活动规则图片删除之前
    beforeRemove: function beforeRemove(file, fileList) {
      var _this4 = this;

      return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F")).then(function () {
        _this4.ruleFile = [];
        _this4.selectedRow.videoUploadInfoDTO = '';
      });
    },
    // 活动规则图片上传文件超出个数限制时
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u53EA\u80FD\u4E0A\u4F20\u4E00\u4EFD\u6587\u4EF6\u54E6\uFF01");
    },
    handleUploadPicture: function handleUploadPicture(_ref) {
      var _this5 = this;

      var file = _ref.file;
      this.ruleFile.push(file);

      if (this.ruleFile.length && this.ruleFile[0] instanceof File) {
        _CommonService.default.uploadSingleTemp(this.ruleFile[0]).then(function (result) {
          var arr = result.data[0].uname.split('.');
          _this5.selectedRow.videoUploadInfoDTO = {
            fileType: arr[1],
            fileName: result.data[0].name,
            infoUuid: arr[0],
            videoUrl: {
              name: result.data[0].name,
              uname: result.data[0].uname,
              url: result.data[0].url
            },
            secUuid: result.data[0].secUuid
          };
        });
      } else if (this.ruleFile.length === 0) {
        this.selectedRow.videoUploadInfoDTO = null;
      }
    }
  },
  created: function created() {
    var _this6 = this;

    // 客户信息类型数据字典
    _ActivityManager.default.dictListGet("BROADCAST_CUS_INFO_TYPE").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this6.informationTypeOptions = result.data[0];
    }); // 播放顺序数据字典


    _ActivityManager.default.dictListGet("BROADCAST_PLAY_ORDER").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this6.playOrderOptions = result.data[0];
    }); // 编辑反显
    // if(this.row){
    //   this.selectedRow = this.row
    // }


    this.getBroadcastData();
  }
};
exports.default = _default;