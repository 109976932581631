var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.btnLoading,
          expression: "btnLoading"
        }
      ],
      staticClass: "app-container",
      attrs: {
        "element-loading-text": "看板下载中，请稍等",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)"
      }
    },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.queryParam }
        },
        [
          _c(
            "el-form-item",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择礼品类型" },
                      model: {
                        value: _vm.queryParam.giftType,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParam, "giftType", $$v)
                        },
                        expression: "queryParam.giftType"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "全部", value: "null" }
                      }),
                      _vm._l(_vm.giftTypeOptions, function(item) {
                        return _c("el-option", {
                          key: item.dictValue,
                          attrs: {
                            label: item.dictLabel,
                            value: item.dictValue
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择渠道来源" },
                      model: {
                        value: _vm.queryParam.channelSource,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParam, "channelSource", $$v)
                        },
                        expression: "queryParam.channelSource"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "全部", value: "null" }
                      }),
                      _vm._l(_vm.channelTypeOptions, function(item) {
                        return _c("el-option", {
                          key: item.dictValue,
                          attrs: {
                            label: item.dictLabel,
                            value: item.dictValue
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入礼品名称" },
                    model: {
                      value: _vm.queryParam.giftNm,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParam, "giftNm", $$v)
                      },
                      expression: "queryParam.giftNm"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.handleAdd } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", icon: "el-icon-plus" } },
                    [_vm._v("新增 ")]
                  ),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    _vm._l(_vm.giftTypeOptions, function(item) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: item.dictValue,
                          attrs: { command: _vm.beforeCommand(item) }
                        },
                        [_vm._v(_vm._s(item.dictLabel))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.handleSyn } },
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("平台商品同步 ")
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.platformArr, function(item) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: item.dictValue,
                          attrs: { command: _vm.beforeSynCommand(item) }
                        },
                        [_vm._v(_vm._s(item.dictLabel))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.handleBatchDelete }
                },
                [_vm._v("删除")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "cell-style": { "text-align": "center" },
            "header-cell-style": { "text-align": "center" },
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "selection-change": _vm.handleSelected }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "60", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "礼品名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.giftName))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "礼品类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm.giftTypePreview(scope.row.giftType)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "渠道来源" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm.channelTypePreview(scope.row.channelSource))
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "库存" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.inventory))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "已领取" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.receiveNumber))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "礼品单价（元）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.giftUnitPrice))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              align: "center",
              width: "240"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text", circle: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleDetail(row)
                          }
                        }
                      },
                      [_vm._v("详情 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text", circle: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v("编辑 ")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "好的",
                          "cancel-button-text": "取消",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "您确认要删除这条数据吗?"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "text-red",
                            staticStyle: { color: "red" },
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "text",
                              circle: ""
                            },
                            slot: "reference"
                          },
                          [_vm._v(" 删除 ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.queryParam.pageNo,
              "page-size": _vm.queryParam.pageSize,
              total: _vm.queryParam.totalCount,
              "page-sizes": [10, 20, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:total": function($event) {
                return _vm.$set(_vm.queryParam, "totalCount", $event)
              },
              "size-change": function($event) {
                return _vm.handleQuery("page")
              },
              "current-change": function($event) {
                return _vm.handleQuery("page")
              }
            }
          })
        ],
        1
      ),
      _vm.SaveDialog
        ? _c("SaveDialog", {
            attrs: {
              row: _vm.selectedRow,
              visible: _vm.SaveDialog,
              isDetail: _vm.isDetail,
              addType: _vm.addType
            },
            on: {
              "update:visible": function($event) {
                _vm.SaveDialog = $event
              },
              ok: _vm.handleQuery
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }