"use strict";

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      runing: true,
      winnerList: [{
        id: '1',
        name: '10积分',
        logo: '../../assets/images/15jf@2x.png',
        probability: '20%'
      }, {
        id: '2',
        name: '10经验值',
        logo: '../../assets/images/10j@2x.png',
        probability: '10%'
      }, {
        id: '3',
        name: '100元抵用券',
        logo: '../../assets/images/123.png',
        probability: '10%'
      }, {
        id: '4',
        name: '15积分',
        logo: '../../assets/images/15jf@2x.png',
        probability: '10%'
      }, {
        id: '5',
        name: '20现金券',
        logo: '../../assets/images/12.png',
        probability: '10%'
      }, {
        id: '6',
        name: '25经验值',
        logo: '../../assets/images/25j@2x.png',
        probability: '10%'
      }, {
        id: '7',
        name: '谢谢参与',
        logo: '../../assets/images/xx@2x.png',
        probability: '20%'
      }, {
        id: '8',
        name: '25积分',
        logo: '../../assets/images/25jf@2x.png',
        probability: '10%'
      }, {
        id: '9',
        name: '靠海别墅一栋',
        logo: '../../assets/images/xx@2x.png',
        probability: '30%'
      }, {
        id: '10',
        name: '玛莎拉蒂一辆',
        logo: '../../assets/images/xx@2x.png',
        probability: '40%'
      }],
      //奖品列表
      color: ["#fde284", "#fe9103", "rgba(0,0,0,0.5)", "#ffffff", "#b10105", "#fbc605"],
      //转盘初始化
      radius: 130,
      // 园的半径
      angles: 0,
      jpNum: 0,
      rotateStyle: {},
      turns: 9,
      // 圈数
      runTime: 10,
      // 转圈秒数
      deg: 0,
      // 记录转动后的角度 可大于360
      rotate: 0 // 在360度内 最后指针停下的角度

    };
  },
  mounted: function mounted() {
    this.canvasRun();
  },
  methods: {
    //绘制转盘
    canvasRun: function canvasRun() {
      var canvas = this.$refs.myCanvas;
      var ctx = canvas.getContext('2d'); // canvas的实际渲染倍率

      var ratio = 3.5; //清晰度

      canvas.style.width = canvas.width;
      canvas.style.height = canvas.height;
      canvas.width = canvas.width * ratio;
      canvas.height = canvas.height * ratio;
      this.createCircle(ratio, ctx);
      this.createCirText(ratio, ctx);
    },
    //外圆
    createCircle: function createCircle(ratio, ctx) {
      var startAngle = 0; //扇形的开始弧度

      var endAngle = 0; //扇形的终止弧度

      var number = this.winnerList.length;
      var radius = this.radius;
      this.getCircleOffset(); //画一个8等份扇形组成的圆形

      for (var i = 0; i < number; i++) {
        if (number % 4 == 0) {
          startAngle = Math.PI * 2 * i / number - Math.PI / number;
        } else if (number % 4 == 1 || number == 1) {
          startAngle = Math.PI * 2 * i / number + Math.PI / number / 2;
        } else if (number % 4 == 2 || number == 2) {
          startAngle = Math.PI * 2 * i / number;
        } else if (number % 4 == 3 || number == 3) {
          startAngle = Math.PI * 2 * i / number - Math.PI / number / 2;
        } else {
          startAngle = 0;
        }

        endAngle = startAngle + Math.PI * 2 / number;
        ctx.save();
        ctx.beginPath();
        ctx.arc(radius * ratio, radius * ratio, radius * ratio, startAngle, endAngle, false);
        ctx.lineWidth = 220 * ratio;

        if (i % 2 == 0) {
          ctx.strokeStyle = "#f8c950";
        } else {
          ctx.strokeStyle = "#fbfbe0";
        }

        ctx.stroke();
        ctx.restore();
      }
    },
    //各奖项
    createCirText: function createCirText(ratio, ctx) {
      ctx.textAlign = 'start';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = this.color[3];
      var that = this;
      var number = this.winnerList.length;
      var radius = this.radius;
      var step = 2 * Math.PI / number;

      for (var i = 0; i < number; i++) {
        (function (arg) {
          var img = new Image();
          img.src = that.winnerList[arg].logo;

          img.onload = function () {
            ctx.save();
            ctx.scale(ratio, ratio);
            ctx.beginPath();
            ctx.translate(radius, radius);
            ctx.rotate(arg * step);
            ctx.font = "10px Microsoft YaHei";
            ctx.textAlign = 'center';
            ctx.fillStyle = '#666666';
            ctx.fillText(that.winnerList[arg].name, 0, -75, 50);
            ctx.drawImage(img, -18, -115, 35, 35);
            ctx.closePath();
            ctx.restore();
          }; // 没有图片时也需要写文字


          img.onerror = function () {
            ctx.save();
            ctx.beginPath();
            ctx.scale(ratio, ratio);
            ctx.translate(radius, radius);
            ctx.rotate(arg * step);
            ctx.textAlign = 'center';
            ctx.font = "8px Microsoft YaHei";
            ctx.fillStyle = that.color[2];
            ctx.fillText(that.winnerList[arg].name, 0, -110, 50);
            ctx.closePath();
            ctx.restore();
          };
        })(i);
      }
    },
    // 计算扇形的偏移量，以保证12点钟方向指向扇形区域的中间
    getCircleOffset: function getCircleOffset() {
      // 到12点钟方向的偏移量
      var number = this.winnerList.length;
      var offset = 0; // var verticalOffset = Math.PI / 2;

      if (number % 2 != 0) {
        offset = Math.PI * 2 / number;
      }

      if (number % 2 == 0 && number / 2 & 2 != 0) {
        offset = 0;
      } else {}

      return offset;
    },
    //点击抽奖
    startRun: function startRun() {
      //转盘旋转过程“开始抽奖”按钮无法点击
      this.runing = false; //前端计算随机数

      var max = this.winnerList.length - 1;
      var num = Math.floor(Math.random() * (0 - max) + max); // console.log('后台接口返回的奖品序号:'+this.jpNum);
      // this.runCup(this.jpNum, this.winnerList[this.jpNum]);

      this.runCup(num, this.winnerList[num]);
    },
    //转盘旋转
    runCup: function runCup(i, item) {
      var that = this;
      var angles = i * (360 / this.winnerList.length);

      if (angles == 90 || angles == 180 || angles == 270) {
        angles = angles + 1;
      }

      this.deg = this.deg % 360 + (360 - this.deg % 360) - angles + 360 * this.turns; // console.log('旋转角度'+ this.deg)
      // console.log('目标角度'+ angles % 360)
      // console.log('上次位置'+ this.deg % 360)

      this.rotateStyle = '-webkit-transform: rotate(' + this.deg + 'deg);transform: rotate(' + this.deg + 'deg);transition:all ' + this.runTime + 's cubic-bezier(0,1,0,1)';
      this.rotate = this.deg % 360;
      setTimeout(function () {
        that.rotateStyle = '-webkit-transform: rotate(' + that.rotate + 'deg);transform: rotate(' + that.rotate + 'deg);';
        that.runing = true;
        that.$message({
          message: '恭喜你，获得' + item.name,
          type: 'success'
        });
      }, this.runTime * 1000);
    }
  }
};
exports.default = _default;