"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SysUserList = _interopRequireDefault(require("@/api/entity/SysUserList"));

var _Page = _interopRequireDefault(require("@/utils/entity/Page"));

var _RoleService = _interopRequireDefault(require("@/api/RoleService"));

var _CommonService = _interopRequireDefault(require("@/api/CommonService"));

var _SysUserListService = _interopRequireDefault(require("@/api/SysUserListService"));

var _permission = _interopRequireDefault(require("@/directive/permission"));

var _convertImageUrl2 = _interopRequireDefault(require("@/utils/convertImageUrl"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  directives: {
    permission: _permission.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: _SysUserList.default,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      roles: [],
      rtem: '',
      rules: {
        userCode: [{
          required: true,
          message: '请输入登录用户名',
          trigger: 'blur'
        }, {
          min: 5,
          max: 15,
          message: '长度在 5 到 15 个字符',
          trigger: 'blur'
        }],
        nickName: [{
          required: true,
          message: '请输入用户昵称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 30,
          message: '长度在 1 到 30 个字符',
          trigger: 'blur'
        }],
        email: [{
          type: 'email',
          message: '请输入正确的邮箱地址',
          trigger: ['blur']
        }],
        mobile: [{
          min: 11,
          max: 11,
          message: '必须是 11 位数字',
          trigger: ['blur']
        }, {
          validator: function validator(rule, value, callback) {
            if (!value) {
              callback();
            }

            if (/1[345789]\d{9}/.test(value)) {
              callback();
            } else {
              callback(new Error('必须是 11 位数字'));
            }
          },
          trigger: 'blur'
        }],
        roleList: [{
          required: true,
          message: '请选择角色',
          trigger: 'blur'
        }]
      },
      // typeOptions: [
      //   {label: '行内员工', value: 'bankUser'},
      //   {label: '自录', value: 'defUser'}
      // ],
      innerVisible: false,
      listLoading: false,
      list: [],
      queryParam: {
        pageNo: _Page.default.ofDefault().number,
        pageSize: _Page.default.ofDefault().size
      }
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    },
    convertImageUrl: function convertImageUrl() {
      return function (url) {
        return (0, _convertImageUrl2.default)(url);
      };
    }
  },
  created: function created() {
    this.handleOpen();
  },
  // mounted() {
  //   console.log('mounted')
  // },
  // beforeDestroy() {
  //   console.log(this.$refs)
  //   this.$refs['rowForm'].clearValidate()
  // },
  // updated() {
  //   console.log('updated')
  //   console.log(this.$refs)
  // },
  methods: {
    handleDetail: function handleDetail(row) {
      this.row.nickName = row.employeeName;
      this.row.userCode = row.employeeNo;
      this.row.mobile = row.mobile;
      this.row.email = row.email;
      this.row.orgnCode = row.websiteId;
      this.row.orgnName = row.orgnName;
      this.innerVisible = false;
    },
    handleQuery: function handleQuery() {
      var _this = this;

      this.listLoading = true;

      _SysUserListService.default.peopleTable(this.queryParam).then(function (result) {
        _this.listLoading = false;
        _this.list = result.data.map(function (row) {
          row.visible = false;
          return row;
        });
        _this.queryParam.pageCount = result.pageCount;
        _this.queryParam.totalCount = result.totalCount;
        result.hasFailure(function () {
          return _this.$message.error(result.message);
        });
      });
    },
    changePeople: function changePeople() {
      this.innerVisible = true;
      this.handleQuery();
    },
    handleOpen: function handleOpen() {
      var _this2 = this;

      _RoleService.default.options().then(function (result) {
        result.hasFailure(function () {
          return _this2.$message.warning(result.getErrorMessage('角色列表加载失败'));
        }).hasData(function (data) {
          console.info(data, _this2.row); // data.forEach(d=>{
          //   d.id = d.roleId,
          //   d.name = d.roleName
          // })

          _this2.roles = data;

          if (_this2.row.roleList) {
            // 编辑时填充角色列表
            // this.row.roleList = this.roles.map(id => data.find(row => row.id === id))
            _this2.roles.map(function (e) {
              if (e.id == _this2.row.roleList[0].id) {
                _this2.rtem = e.id;
                return;
              }
            });
          }
        });
      });
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      if (!['image/jpeg', 'image/png', 'image/x-icon'].includes(file.type)) {
        this.$message.error('上传头像图片只能是 jpg,png,ico 格式!');
        return false;
      }

      if (file.size / 1024 > 500) {
        this.$message.error('上传头像图片大小不能超过 500 KB !');
        return false;
      }

      return true;
    },
    handleUpload: function handleUpload(_ref) {
      var file = _ref.file,
          onSuccess = _ref.onSuccess,
          onError = _ref.onError;

      _CommonService.default.uploadUser(file).then(function (result) {
        return result.dataFirst(function (row) {
          return onSuccess(row);
        }, function () {
          return onError('文件上传成功，但服务器未返回文件信息');
        }).hasFailure(onError(result.getErrorMessage('文件上传失败')));
      });
    },
    handleAvatarSuccess: function handleAvatarSuccess(res) {
      this.row.avatarUrl = res;
    },
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this3 = this;

      if (this.rtem != '') {
        this.row.roleList = [];
        var item = this.roles.find(function (row) {
          return _this3.rtem == row.id;
        });
        this.row.roleList.push(item);
      }

      this.$refs.rowForm.validate(function (valid) {
        if (valid) {
          _this3.row.userType = 'bankUser';
          _this3.btnSaveLoading = true;
          (_this3.row.id ? _SysUserListService.default.update(_this3.row) : _SysUserListService.default.save(_this3.row)).then(function (result) {
            result.hasFailure(function () {
              _this3.btnSaveLoading = false;

              _this3.$message.warning(result.getErrorMessage('保存失败'));
            }).hasSuccess(function () {
              _this3.btnSaveLoading = false;

              _this3.$message.success('保存成功');

              _this3.handleCancel();

              _this3.$emit('ok');
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;