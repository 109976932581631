"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _uniqueId = _interopRequireDefault(require("lodash/uniqueId"));

var _charts = _interopRequireDefault(require("@/components/Charts/mixin/charts"));

//
//
//
//
//
//
var _default = {
  mixins: [_charts.default],
  props: {
    data: {
      type: Array,
      default: null
    }
  },
  data: function data() {
    return {
      timepick: '',
      charts: {
        chart: {
          id: (0, _uniqueId.default)('chart_'),
          instance: null,
          // charts mixin 时会通过混入的方式初始化 echarts 实例到这个属性
          option: {
            tooltip: {
              trigger: 'item',
              formatter: '{b} : {c} ({d}%)'
            },
            grid: {
              left: '1%',
              right: '1%',
              bottom: '3%',
              top: '10%',
              containLabel: true
            },
            series: [{
              type: 'pie',
              radius: '50%',
              label: {
                formatter: '{b}\n{c} ({d}%)'
              },
              data: this.data,
              itemStyle: {
                normal: {
                  color: function color(colors) {
                    var colorList = ['#64C466', '#FFBF6B', '#5AC4F6', '#BBBBBB', '#EE752F', '#B3F059', '#BFBF3D', '#6EE7F5', '#FF8C82', '#7F83F7', '#045682', '#D95040'];
                    return colorList[colors.dataIndex];
                  }
                }
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          }
        }
      }
    };
  }
};
exports.default = _default;