"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.keys.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GroupStatusEnum = exports.OrderTypeEnum = exports.ToHoNorStatusEnum = exports.SwitchFlagEnum = exports.AllowDividendMethodEnum = exports.ProductStatusEnum = exports.ProductTypeEnum2 = exports.ProductTypeEnum = exports.ProductActivityTypeEnum = exports.AccountTypeEnum = exports.LabelSexEnum = exports.LabelPlatformEnum = exports.LabelPhoneBrandsEnum = exports.LabelOperatorEnum = exports.LabelNewUserEnum = exports.LabelAreaTypeEnum = exports.LabelAreaGroupEnum = exports.LabelAgeEnum = exports.TaskTypeEnum = exports.TaskCycleEnum = exports.ChooseProductWorkDateEnum = exports.CustomerRestrictionsEnum = exports.EarningInterestEnum = exports.IncomeDistributionEnum = exports.DividendMethodEnum = exports.FundingModelEnum = exports.AllowTransactionsEnum = exports.AllowSalesCustomerTypeEnum = exports.ProductCategoryEnum = exports.SalesStatusEnum = exports.ExchangeMarkEnum = exports.IncomeFeatureEnum = exports.MarketingModelEnum = exports.ProductCurrencyEnum = exports.RiskLevelEnum = exports.InvestmentTrendsEnum = exports.ProductFeatureEnum = exports.ProductBigTypeEnum = exports.Characteristics = exports.PeopleScopeEnum = exports.FileUploadUrl = exports.FileTypeMap = exports.FileTypes = exports.ValidRecordEnum = exports.CouponStateEnum = exports.CouponUseStateEnum = exports.CouponTypeEnum = exports.protocolTypeEnum = exports.BannerTypeEnum = exports.LogMap = exports.ProductTypes = exports.DemoStatus = exports.AuthorityCode = exports.LoginPlatformEnum = exports.Bool = exports.Item = void 0;

var _classCallCheck2 = _interopRequireDefault(require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/classCallCheck"));

var _this = void 0;

/**
 * 枚举选项通用对象
 */
var Item =
/**
 *
 * @param key {string} 后台接口需要的值
 * @param comment {string} 前端显示值
 * @param deprecated {boolean} 是否废弃
 * @param checked {boolean} 默认是否选中
 */
function Item(key, comment) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$deprecated = _ref.deprecated,
      deprecated = _ref$deprecated === void 0 ? false : _ref$deprecated,
      _ref$checked = _ref.checked,
      checked = _ref$checked === void 0 ? false : _ref$checked;

  (0, _classCallCheck2.default)(this, Item);

  /**
   * 后台接口需要的值
   * @type {string}
   */
  this.key = key;
  /**
   * 前端显示值
   * @type {string}
   */

  this.comment = comment;
  /**
   * 是否废弃
   * @type {boolean}
   */

  this.deprecated = deprecated;
  /**
   * 默认是否选中
   * @type {boolean}
   */

  this.checked = checked;
};
/**
 * 通用枚举：只有 0 或 1 两种状态
 * @type {{NO: Item, YES: Item, options: (function(string, string): Item[])}}
 */


exports.Item = Item;
var Bool = {
  NO: new Item('NO', '是|已删除|启用|已读|已处理|已验证|已完成|  支持|已确认|有效|已过期|启动'),
  YES: new Item('YES', '否|未删除|禁用|未读|待处理|未验证|未完成|不支持|未确认|无效|未过期|暂停'),

  /**
   * 获取选项集合
   * @param yes {string} YES 选项文本
   * @param no {string} NO 选项文本
   * @return {Item[]} => [{key:'YES',components:'yes'}, {key:'NO',components:'no'}]
   */
  options: function options(yes, no) {
    return [new Item(_this.YES.key, yes), new Item(_this.NO.key, no)];
  }
};
/**
 * 用户登录平台
 * @type {{Admin: Item, Member: Item, Member_Manager: Item}}
 */

exports.Bool = Bool;
var LoginPlatformEnum = {
  Admin: new Item('Admin', '管理端'),
  Member: new Item('Member', '客户'),
  Member_Manager: new Item('Member_Manager', '客户经理'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(LoginPlatformEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _LoginPlatformEnum$ke = LoginPlatformEnum[key],
          comment = _LoginPlatformEnum$ke.comment,
          deprecated = _LoginPlatformEnum$ke.deprecated,
          checked = _LoginPlatformEnum$ke.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 角色权限代码
 * @type {{Menu_Setting_System: Item, Menu_Manage_User: Item, Menu_Manage_Role_List: Item, Menu_Setting: Item, Menu_Manage_User_List: Item, Menu_Setting_Personal: Item, ROLE_ADMIN: Item}}
 */

exports.LoginPlatformEnum = LoginPlatformEnum;
var AuthorityCode = {
  ROLE_ADMIN: new Item('ROLE_ADMIN', '管理员角色权限代码'),
  Menu_Home: new Item('Menu_Home', '首页'),
  // 用户列表
  Menu_Manage_User_List: new Item('Menu_Manage_User_List', '用户列表'),
  User_List_load: new Item('User_List_load', '查'),
  User_List_save: new Item('User_List_save', '增'),
  User_List_delete: new Item('User_List_delete', '删'),
  User_List_update: new Item('User_List_update', '改'),
  // 角色管理
  Menu_Manage_Role_List: new Item('Menu_Manage_Role_List', '角色列表'),
  Role_load: new Item('Role_load', '查'),
  Role_save: new Item('Role_save', '增'),
  Role_delete: new Item('Role_delete', '删'),
  Role_update: new Item('Role_update', '改'),
  // 产品
  Menu_Manage_Product: new Item('Menu_Manage_Product', '产品管理'),
  Product_load: new Item('Product_load', '产品列表'),
  Product_save: new Item('Product_save', '产品新增'),
  Product_update: new Item('Product_update', '产品修改'),
  Product_delete: new Item('Product_delete', '产品删除'),
  // 兑换商品
  Menu_Manage_Redemption: new Item('Menu_Manage_Redemption', '用户管理'),
  Redemption_load: new Item('Redemption_load', '用户列表'),
  Redemption_save: new Item('Redemption_save', '用户新增'),
  Redemption_update: new Item('Redemption_update', '用户修改'),
  Redemption_delete: new Item('Redemption_delete', '用户删除'),
  // 游戏配置
  Menu_Manage_GAME: new Item('Menu_Manage_GAME', '游戏配置管理'),
  Game_load: new Item('Game_load', '游戏配置列表'),
  Game_save: new Item('Game_save', '游戏配置新增'),
  Game_update: new Item('Game_update', '游戏配置修改'),
  Game_delete: new Item('Game_delete', '游戏配置删除'),
  // 游戏弹幕配置
  Menu_Game_Message: new Item('Menu_Game_Message', '游戏弹幕配置'),
  Game_Message_load: new Item('Game_Message_load', '游戏弹幕列表'),
  Game_Message_save: new Item('Game_Message_save', '游戏弹幕新增'),
  Game_Message_update: new Item('Game_Message_update', '游戏弹幕修改'),
  Game_Message_delete: new Item('Game_Message_delete', '游戏弹幕删除'),
  // 新人礼配置
  Menu_New_Gift: new Item('Menu_New_Gift', '新人礼管理'),
  New_Gift_load: new Item('New_Gift_load', '新人礼列表'),
  New_Gift_save: new Item('New_Gift_save', '新人礼新增'),
  New_Gift_update: new Item('New_Gift_update', '新人礼修改'),
  New_Gift_delete: new Item('New_Gift_delete', '新人礼删除'),
  // 新人礼配置
  Menu_Banner: new Item('Menu_Banner', '轮播管理'),
  Banner_load: new Item('Banner_load', '轮播列表'),
  Banner_save: new Item('Banner_save', '轮播新增'),
  Banner_update: new Item('Banner_update', '轮播修改'),
  Banner_delete: new Item('Banner_delete', '轮播删除'),
  Menu_Goods_Exchange: new Item('Menu_Goods_Exchange', '用户兑换商品记录'),
  Goods_Exchange_load: new Item('Goods_Exchange_load', '兑换记录列表'),
  // 产品购买列表
  Menu_Buy_Product: new Item('Menu_Buy_Product', '产品购买记录'),
  Buy_Product_load: new Item('Buy_Product_load', '产品购买记录列表'),
  // 用户标签 *********************************************************************************************************
  Menu_User_Tag_List: new Item('Menu_User_Tag_List', '用户标签'),
  User_Tag_load: new Item('User_Tag_load', '用户标签查询'),
  User_Tag_update: new Item('User_Tag_update', '用户标签修改'),
  User_Tag_save: new Item('User_Tag_save', '用户标签保存'),
  User_Tag_delete: new Item('User_Tag_delete', '用户标签删除'),
  // fixme sunguolong 只是个简单占位，随后要删除
  Product_Disabled1: new Item('占位', '占位')
};
/**
 * 枚举状态：
 * @type {{SUCCESS: Item, RUNNING: Item, WATING: Item, options: (function(): Item[]), FAILURE: Item}}
 */

exports.AuthorityCode = AuthorityCode;
var DemoStatus = {
  WATING: new Item('WATING', '等待中'),
  RUNNING: new Item('RUNNING', '执行中'),
  SUCCESS: new Item('SUCCESS', '成功'),
  FAILURE: new Item('FAILURE', '失败'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(DemoStatus).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _DemoStatus$key = DemoStatus[key],
          comment = _DemoStatus$key.comment,
          deprecated = _DemoStatus$key.deprecated,
          checked = _DemoStatus$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 产品类型：
 * @type {{JZ: Item, BB: Item, GD: Item}}
 */

exports.DemoStatus = DemoStatus;
var ProductTypes = {
  JZ: new Item('JZ', '净值型'),
  BB: new Item('BB', '保本收益'),
  GD: new Item('GD', '固定收益'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ProductTypes).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ProductTypes$key = ProductTypes[key],
          comment = _ProductTypes$key.comment,
          deprecated = _ProductTypes$key.deprecated,
          checked = _ProductTypes$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 *  积分日志类型
 * */

exports.ProductTypes = ProductTypes;
var LogMap = {
  DRAINAGE: '引流',
  NEWCUSTOM: '拉新',
  EXCHANGE_COUPON: '优惠券兑换',
  EXCHANGE_PRODUCT: '转化'
};
/**
 * 轮播图类型
 * @type {{MANAGER_HOT_ACTIVITY: Item, options: (function(): Item[])}}
 * */

exports.LogMap = LogMap;
var BannerTypeEnum = {
  MANAGER_HOT_ACTIVITY: new Item('MANAGER_HOT_ACTIVITY', '客户经理端热门活动'),
  ACCESSORY: new Item('ACCESSORY', '合伙人Banner'),
  FuGunXin: new Item('FuGunXin', '富贵薪'),
  PUBLICITY_PAGE: new Item('PUBLICITY_PAGE', '北行宣传页'),
  CREDIT_CARD: new Item('CREDIT_CARD', '信用卡'),
  FRIEND_LOAN: new Item('FRIEND_LOAN', '好友贷'),
  FRIEND_GROUP: new Item('FRIEND_GROUP', '好友购'),
  MARKETING: new Item('MARKETING', '营销活动'),
  CLIENT_HOT_ACTIVITY: new Item('CLIENT_HOT_ACTIVITY', '热门活动'),
  MID_AUTUMN_DRAW: new Item('MID_AUTUMN_DRAW', '中秋活动'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(BannerTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _BannerTypeEnum$key = BannerTypeEnum[key],
          comment = _BannerTypeEnum$key.comment,
          deprecated = _BannerTypeEnum$key.deprecated,
          checked = _BannerTypeEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 协议类型
 * @type {{New_Customer: Item,Dragon_Boat_Festival_Special: Item,New_Year_Special: Item, options: (function(): Item[]), FAILURE: Item}}
 * */

exports.BannerTypeEnum = BannerTypeEnum;
var protocolTypeEnum = {
  PURCHASENOTES: new Item('PURCHASENOTES', '产品购买须知'),
  GROUPBOOKNOTES: new Item('GROUPBOOKNOTES', '关于拼团活动须知'),
  SECKILLNOTES: new Item('SECKILLNOTES', '关于秒杀活动须知'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(protocolTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _protocolTypeEnum$key = protocolTypeEnum[key],
          comment = _protocolTypeEnum$key.comment,
          deprecated = _protocolTypeEnum$key.deprecated,
          checked = _protocolTypeEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 优惠券类型
 * @type {{New_Customer: Item,Dragon_Boat_Festival_Special: Item,New_Year_Special: Item, options: (function(): Item[]), FAILURE: Item}}
 * */

exports.protocolTypeEnum = protocolTypeEnum;
var CouponTypeEnum = {
  INCREASES: new Item('INCREASES', '增息'),
  EXPERIENCEGOLD: new Item('EXPERIENCEGOLD', '体验金'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(CouponTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _CouponTypeEnum$key = CouponTypeEnum[key],
          comment = _CouponTypeEnum$key.comment,
          deprecated = _CouponTypeEnum$key.deprecated,
          checked = _CouponTypeEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 优惠券状态
 * @type {{New_Customer: Item,Dragon_Boat_Festival_Special: Item,New_Year_Special: Item, options: (function(): Item[]), FAILURE: Item}}
 * */

exports.CouponTypeEnum = CouponTypeEnum;
var CouponUseStateEnum = {
  NOTENABLED: new Item('NOTENABLED', '未启用'),
  ISENABLED: new Item('ISENABLED', '已启用'),
  DISABLED: new Item('DISABLED', '已失效'),
  CANCEL: new Item('CANCEL', '已取消'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(CouponUseStateEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _CouponUseStateEnum$k = CouponUseStateEnum[key],
          comment = _CouponUseStateEnum$k.comment,
          deprecated = _CouponUseStateEnum$k.deprecated,
          checked = _CouponUseStateEnum$k.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 我的优惠券状态
 * @type {{New_Customer: Item,Dragon_Boat_Festival_Special: Item,New_Year_Special: Item, options: (function(): Item[]), FAILURE: Item}}
 * */

exports.CouponUseStateEnum = CouponUseStateEnum;
var CouponStateEnum = {
  TOBEUSED: new Item('TOBEUSED', '待使用'),
  HAVEBEENUSED: new Item('HAVEBEENUSED', '已使用'),
  HAVEEXPIRED: new Item('HAVEEXPIRED', '已过期'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(CouponStateEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _CouponStateEnum$key = CouponStateEnum[key],
          comment = _CouponStateEnum$key.comment,
          deprecated = _CouponStateEnum$key.deprecated,
          checked = _CouponStateEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 我的优惠券状态
 * @type {{New_Customer: Item,Dragon_Boat_Festival_Special: Item,New_Year_Special: Item, options: (function(): Item[]), FAILURE: Item}}
 * */

exports.CouponStateEnum = CouponStateEnum;
var ValidRecordEnum = {
  NO: new Item('NO', '无效记录'),
  YES: new Item('YES', '有效记录'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(CouponStateEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _CouponStateEnum$key2 = CouponStateEnum[key],
          comment = _CouponStateEnum$key2.comment,
          deprecated = _CouponStateEnum$key2.deprecated,
          checked = _CouponStateEnum$key2.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
exports.ValidRecordEnum = ValidRecordEnum;
var FileTypes = ['jpg', 'jpeg', 'png', 'rar', 'zip', 'dwg', 'pdf', 'text', 'doc', 'xls', 'ppt', 'docx', 'xlsx', 'pptx'];
exports.FileTypes = FileTypes;
var FileTypeMap = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  rar: 'application/x-rar-compressed',
  zip: 'application/zip',
  dwg: 'application/application/x-autocad',
  pdf: 'application/pdf',
  text: 'text/plain',
  doc: 'application/msword',
  xls: 'application/vnd.ms-excel',
  ppt: 'application/mspowerpoint',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
};
exports.FileTypeMap = FileTypeMap;
var FileUploadUrl = {
  uploadTemp: '/v1/open/common/upload/temp',
  // 上传到临时目录，单个上传
  uploadsTemp: '/v1/open/common/upload/batch/temp',
  // 上传到临时目录，批量上传
  // uploadUser: '/common/upload/user', // 上传到用户目录，单个上传：用户头像
  uploadUser: '/v1/open/common/upload/user',
  // 上传到用户目录，单个上传：用户头像
  uploadsUser: '/v1/open/common/upload/batch/user',
  // 上传到用户目录，批量上传：用户头像
  releaseUploadTemp: '/web/auth/file/upload/single' // 上传到临时目录，单个上传

};
/**
 * 用户类型
 *  @type {{New_Customer: Item,Dragon_Boat_Festival_Special: Item,New_Year_Special: Item, options: (function(): Item[]), FAILURE: Item}}
 * */

exports.FileUploadUrl = FileUploadUrl;
var PeopleScopeEnum = {
  ORDINARY: new Item('ORDINARY', '普通用户'),
  CUSTOM_MANAGER: new Item('CUSTOM_MANAGER', '客户经理'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(Characteristics).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _Characteristics$key = Characteristics[key],
          comment = _Characteristics$key.comment,
          deprecated = _Characteristics$key.deprecated,
          checked = _Characteristics$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 产品特色：
 * @type {{New_Customer: Item,Dragon_Boat_Festival_Special: Item,New_Year_Special: Item, options: (function(): Item[]), FAILURE: Item}}
 */

exports.PeopleScopeEnum = PeopleScopeEnum;
var Characteristics = {
  New_Customer: new Item('New_Customer', '新客专属'),
  Dragon_Boat_Festival_Special: new Item('Dragon_Boat_Festival_Special', '端午节特别版'),
  New_Year_Special: new Item('Dragon_Boat_Festival_Special', '新春特别版'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(Characteristics).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _Characteristics$key2 = Characteristics[key],
          comment = _Characteristics$key2.comment,
          deprecated = _Characteristics$key2.deprecated,
          checked = _Characteristics$key2.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 产品类型枚举
 * @type {{ZQ: Item}}
 */

exports.Characteristics = Characteristics;
var ProductBigTypeEnum = {
  ZQ: new Item('ZQ', '周期净值型'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ProductBigTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ProductBigTypeEnum$k = ProductBigTypeEnum[key],
          comment = _ProductBigTypeEnum$k.comment,
          deprecated = _ProductBigTypeEnum$k.deprecated,
          checked = _ProductBigTypeEnum$k.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 产品特征
 * @type {{Open: Item, Close: Item}}
 */

exports.ProductBigTypeEnum = ProductBigTypeEnum;
var ProductFeatureEnum = {
  Open: new Item('Open', '开放型'),
  Close: new Item('Close', '封闭型'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ProductFeatureEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ProductFeatureEnum$k = ProductFeatureEnum[key],
          comment = _ProductFeatureEnum$k.comment,
          deprecated = _ProductFeatureEnum$k.deprecated,
          checked = _ProductFeatureEnum$k.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 投资方向
 * @type {{Other: Item}}
 */

exports.ProductFeatureEnum = ProductFeatureEnum;
var InvestmentTrendsEnum = {
  Other: new Item('Other', '其他'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(InvestmentTrendsEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _InvestmentTrendsEnum = InvestmentTrendsEnum[key],
          comment = _InvestmentTrendsEnum.comment,
          deprecated = _InvestmentTrendsEnum.deprecated,
          checked = _InvestmentTrendsEnum.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 风险等级
 * @type {{High: Item, Medium: Item, Low: Item}}
 */

exports.InvestmentTrendsEnum = InvestmentTrendsEnum;
var RiskLevelEnum = {
  High: new Item('High', '高风险'),
  Medium: new Item('Medium', '中等风险'),
  Low: new Item('Low', '低风险'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(RiskLevelEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _RiskLevelEnum$key = RiskLevelEnum[key],
          comment = _RiskLevelEnum$key.comment,
          deprecated = _RiskLevelEnum$key.deprecated,
          checked = _RiskLevelEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 币种
 * @type {{RMB: Item, USD: Item, won: Item}}
 */

exports.RiskLevelEnum = RiskLevelEnum;
var ProductCurrencyEnum = {
  RMB: new Item('RMB', '人民币'),
  USD: new Item('USD', '美元'),
  won: new Item('won', '韩元'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ProductCurrencyEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ProductCurrencyEnum$ = ProductCurrencyEnum[key],
          comment = _ProductCurrencyEnum$.comment,
          deprecated = _ProductCurrencyEnum$.deprecated,
          checked = _ProductCurrencyEnum$.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 营销模式
 * @type {{Direct: Item, Other: Item}}
 */

exports.ProductCurrencyEnum = ProductCurrencyEnum;
var MarketingModelEnum = {
  Direct: new Item('Direct', '直销'),
  Other: new Item('Other', '他销'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(MarketingModelEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _MarketingModelEnum$k = MarketingModelEnum[key],
          comment = _MarketingModelEnum$k.comment,
          deprecated = _MarketingModelEnum$k.deprecated,
          checked = _MarketingModelEnum$k.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 收益特征
 * @type {{A: Item, B: Item}}
 */

exports.MarketingModelEnum = MarketingModelEnum;
var IncomeFeatureEnum = {
  A: new Item('A', '非保本浮动型收益'),
  B: new Item('B', '保本浮动型收益'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(IncomeFeatureEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _IncomeFeatureEnum$ke = IncomeFeatureEnum[key],
          comment = _IncomeFeatureEnum$ke.comment,
          deprecated = _IncomeFeatureEnum$ke.deprecated,
          checked = _IncomeFeatureEnum$ke.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 炒汇标识
 * @type {{Cash: Item, AliPay: Item, WeChatPay: Item}}
 */

exports.IncomeFeatureEnum = IncomeFeatureEnum;
var ExchangeMarkEnum = {
  Cash: new Item('Cash', '现钞'),
  AliPay: new Item('AliPay', '支付宝'),
  WeChatPay: new Item('WeChatPay', '微信'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ExchangeMarkEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ExchangeMarkEnum$key = ExchangeMarkEnum[key],
          comment = _ExchangeMarkEnum$key.comment,
          deprecated = _ExchangeMarkEnum$key.deprecated,
          checked = _ExchangeMarkEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 销售状态
 * @type {{Normal: Item, Abnormal: Item}}
 */

exports.ExchangeMarkEnum = ExchangeMarkEnum;
var SalesStatusEnum = {
  SELL_WELL: new Item('SELL_WELL', '热销'),
  NEW_HAIR: new Item('NEW_HAIR', '新发'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(SalesStatusEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _SalesStatusEnum$key = SalesStatusEnum[key],
          comment = _SalesStatusEnum$key.comment,
          deprecated = _SalesStatusEnum$key.deprecated,
          checked = _SalesStatusEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 产品分类
 * @type {{Special: Item, Normal: Item}}
 */

exports.SalesStatusEnum = SalesStatusEnum;
var ProductCategoryEnum = {
  Special: new Item('Special', '特殊'),
  Normal: new Item('Normal', '正常'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ProductCategoryEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ProductCategoryEnum$ = ProductCategoryEnum[key],
          comment = _ProductCategoryEnum$.comment,
          deprecated = _ProductCategoryEnum$.deprecated,
          checked = _ProductCategoryEnum$.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 允许销售客户类型
 * @type {{Personal: Item, Agency: Item}}
 */

exports.ProductCategoryEnum = ProductCategoryEnum;
var AllowSalesCustomerTypeEnum = {
  Personal: new Item('Personal', '个人'),
  Agency: new Item('Agency', '机构'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(AllowSalesCustomerTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _AllowSalesCustomerTy = AllowSalesCustomerTypeEnum[key],
          comment = _AllowSalesCustomerTy.comment,
          deprecated = _AllowSalesCustomerTy.deprecated,
          checked = _AllowSalesCustomerTy.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 允许交易类型
 * @type {{subscription: Item}}
 */

exports.AllowSalesCustomerTypeEnum = AllowSalesCustomerTypeEnum;
var AllowTransactionsEnum = {
  subscription: new Item('subscription', '产品认购'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(AllowTransactionsEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _AllowTransactionsEnu = AllowTransactionsEnum[key],
          comment = _AllowTransactionsEnu.comment,
          deprecated = _AllowTransactionsEnu.deprecated,
          checked = _AllowTransactionsEnu.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 购买非实时确认资金模式
 * @type {{Freeze: Item, Normal: Item}}
 */

exports.AllowTransactionsEnum = AllowTransactionsEnum;
var FundingModelEnum = {
  Freeze: new Item('Freeze', '冻结'),
  Normal: new Item('Normal', '正常'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(FundingModelEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _FundingModelEnum$key = FundingModelEnum[key],
          comment = _FundingModelEnum$key.comment,
          deprecated = _FundingModelEnum$key.deprecated,
          checked = _FundingModelEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 分红方式
 * @type {{Cash: Item, Share: Item}}
 */

exports.FundingModelEnum = FundingModelEnum;
var DividendMethodEnum = {
  Cash: new Item('Cash', '现金分红'),
  Share: new Item('Share', '股份分红'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(DividendMethodEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _DividendMethodEnum$k = DividendMethodEnum[key],
          comment = _DividendMethodEnum$k.comment,
          deprecated = _DividendMethodEnum$k.deprecated,
          checked = _DividendMethodEnum$k.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 收益分配方式
 * @type {{Actual: Item}}
 */

exports.DividendMethodEnum = DividendMethodEnum;
var IncomeDistributionEnum = {
  Actual: new Item('Actual', '实际到期收益率'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(IncomeDistributionEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _IncomeDistributionEn = IncomeDistributionEnum[key],
          comment = _IncomeDistributionEn.comment,
          deprecated = _IncomeDistributionEn.deprecated,
          checked = _IncomeDistributionEn.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 收益计息方式
 * @type {{Redemption: Item}}
 */

exports.IncomeDistributionEnum = IncomeDistributionEnum;
var EarningInterestEnum = {
  Redemption: new Item('Redemption', '兑付计算'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(EarningInterestEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _EarningInterestEnum$ = EarningInterestEnum[key],
          comment = _EarningInterestEnum$.comment,
          deprecated = _EarningInterestEnum$.deprecated,
          checked = _EarningInterestEnum$.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 客户条件限制
 * @type {{Unlimited: Item}}
 */

exports.EarningInterestEnum = EarningInterestEnum;
var CustomerRestrictionsEnum = {
  Unlimited: new Item('Unlimited', '无限制'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(CustomerRestrictionsEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _CustomerRestrictions = CustomerRestrictionsEnum[key],
          comment = _CustomerRestrictions.comment,
          deprecated = _CustomerRestrictions.deprecated,
          checked = _CustomerRestrictions.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 选择设置产品工作日
 * @type {{ProductWorkDate: Item}}
 */

exports.CustomerRestrictionsEnum = CustomerRestrictionsEnum;
var ChooseProductWorkDateEnum = {
  ProductWorkDate: new Item('ProductWorkDate', '产品工作日'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ChooseProductWorkDateEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ChooseProductWorkDat = ChooseProductWorkDateEnum[key],
          comment = _ChooseProductWorkDat.comment,
          deprecated = _ChooseProductWorkDat.deprecated,
          checked = _ChooseProductWorkDat.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 任务周期
 * @type {{DAY: Item, WEEK: Item, MONTH: Item, QUARTERLY: Item}}
 */

exports.ChooseProductWorkDateEnum = ChooseProductWorkDateEnum;
var TaskCycleEnum = {
  DAY: new Item('DAY', '每日任务'),
  WEEK: new Item('WEEK', '每周任务'),
  MONTH: new Item('MONTH', '每月任务'),
  QUARTERLY: new Item('QUARTERLY', '每季度任务'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(TaskCycleEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _TaskCycleEnum$key = TaskCycleEnum[key],
          comment = _TaskCycleEnum$key.comment,
          deprecated = _TaskCycleEnum$key.deprecated,
          checked = _TaskCycleEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 任务类型
 * @type {{NEW: Item, TRANSFORM: Item}}
 */

exports.TaskCycleEnum = TaskCycleEnum;
var TaskTypeEnum = {
  NEW: new Item('NEW', '拉新'),
  TRANSFORM: new Item('TRANSFORM', '转化'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(TaskTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _TaskTypeEnum$key = TaskTypeEnum[key],
          comment = _TaskTypeEnum$key.comment,
          deprecated = _TaskTypeEnum$key.deprecated,
          checked = _TaskTypeEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 标签年龄枚举
 * @type {{All: Item, _1823: Item, _2430: Item, _3140: Item, _4149: Item, _50: Item}}
 */

exports.TaskTypeEnum = TaskTypeEnum;
var LabelAgeEnum = {
  All: new Item('All', '不限'),
  _1823: new Item('_1823', '18-23'),
  _2430: new Item('_2430', '24-30'),
  _3140: new Item('_3140', '31-40'),
  _4149: new Item('_4149', '41-49'),
  _50: new Item('_50', '50+'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(LabelAgeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _LabelAgeEnum$key = LabelAgeEnum[key],
          comment = _LabelAgeEnum$key.comment,
          deprecated = _LabelAgeEnum$key.deprecated,
          checked = _LabelAgeEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 标签地域分组枚举
 * @type {{Being: Item, Live: Item, Tourism: Item, All: Item}}
 */

exports.LabelAgeEnum = LabelAgeEnum;
var LabelAreaGroupEnum = {
  Being: new Item('Being', '正在该地区的用户'),
  Live: new Item('Live', '居住在该地区的用户'),
  Tourism: new Item('Tourism', '到该地区旅游的用户'),
  All: new Item('All', '在该地区的所有用户'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(LabelAreaGroupEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _LabelAreaGroupEnum$k = LabelAreaGroupEnum[key],
          comment = _LabelAreaGroupEnum$k.comment,
          deprecated = _LabelAreaGroupEnum$k.deprecated,
          checked = _LabelAreaGroupEnum$k.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 标签地域分组类型枚举
 * @type {{All: Item, Province: Item, County: Item, Business: Item}}
 */

exports.LabelAreaGroupEnum = LabelAreaGroupEnum;
var LabelAreaTypeEnum = {
  All: new Item('All', '不限'),
  Province: new Item('Province', '按省市'),
  County: new Item('County', '按区县'),
  Business: new Item('Business', '按商圈'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(LabelAreaTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _LabelAreaTypeEnum$ke = LabelAreaTypeEnum[key],
          comment = _LabelAreaTypeEnum$ke.comment,
          deprecated = _LabelAreaTypeEnum$ke.deprecated,
          checked = _LabelAreaTypeEnum$ke.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 标签新用户枚举
 * @type {{All: Item, One_Month: Item, Three_Month: Item, More_Than: Item}}
 */

exports.LabelAreaTypeEnum = LabelAreaTypeEnum;
var LabelNewUserEnum = {
  All: new Item('All', '不限'),
  One_Month: new Item('One_Month', '一个月以内'),
  Three_Month: new Item('Three_Month', '一个月到三个月'),
  More_Than: new Item('More_Than', '三个月以上'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(LabelNewUserEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _LabelNewUserEnum$key = LabelNewUserEnum[key],
          comment = _LabelNewUserEnum$key.comment,
          deprecated = _LabelNewUserEnum$key.deprecated,
          checked = _LabelNewUserEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 标签运营商枚举
 * @type {{All: Item, _10086: Item, _10010: Item, _10000: Item}}
 */

exports.LabelNewUserEnum = LabelNewUserEnum;
var LabelOperatorEnum = {
  All: new Item('All', '不限'),
  _10086: new Item('_10086', '移动'),
  _10010: new Item('_10010', '联通'),
  _10000: new Item('_10000', '电信'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(LabelOperatorEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _LabelOperatorEnum$ke = LabelOperatorEnum[key],
          comment = _LabelOperatorEnum$ke.comment,
          deprecated = _LabelOperatorEnum$ke.deprecated,
          checked = _LabelOperatorEnum$ke.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 标签手机品牌枚举
 * @type {{All: Item, Apple: Item, XiaoMi: Item, MeiZu: Item}}
 */

exports.LabelOperatorEnum = LabelOperatorEnum;
var LabelPhoneBrandsEnum = {
  All: new Item('All', '不限'),
  Apple: new Item('Apple', '苹果'),
  XiaoMi: new Item('XiaoMi', '小米'),
  MeiZu: new Item('MeiZu', '魅族'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(LabelPhoneBrandsEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _LabelPhoneBrandsEnum = LabelPhoneBrandsEnum[key],
          comment = _LabelPhoneBrandsEnum.comment,
          deprecated = _LabelPhoneBrandsEnum.deprecated,
          checked = _LabelPhoneBrandsEnum.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 标签手机平台枚举
 * @type {{All: Item, IOS: Item, Android: Item, PC: Item}}
 */

exports.LabelPhoneBrandsEnum = LabelPhoneBrandsEnum;
var LabelPlatformEnum = {
  All: new Item('All', '不限'),
  IOS: new Item('IOS', 'IOS'),
  Android: new Item('Android', 'Android'),
  PC: new Item('PC', 'PC'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(LabelPlatformEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _LabelPlatformEnum$ke = LabelPlatformEnum[key],
          comment = _LabelPlatformEnum$ke.comment,
          deprecated = _LabelPlatformEnum$ke.deprecated,
          checked = _LabelPlatformEnum$ke.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 标签性别枚举
 * @type {{All: Item, Boy: Item, Girl: Item}}
 */

exports.LabelPlatformEnum = LabelPlatformEnum;
var LabelSexEnum = {
  All: new Item('All', '不限'),
  Boy: new Item('Boy', '男'),
  Girl: new Item('Girl', '女'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(LabelSexEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _LabelSexEnum$key = LabelSexEnum[key],
          comment = _LabelSexEnum$key.comment,
          deprecated = _LabelSexEnum$key.deprecated,
          checked = _LabelSexEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 用户类型
 *  @type {{New_Customer: Item,Dragon_Boat_Festival_Special: Item,New_Year_Special: Item, options: (function(): Item[]), FAILURE: Item}}
 * */

exports.LabelSexEnum = LabelSexEnum;
var AccountTypeEnum = {
  SYSTEM: new Item('SYSTEM', '后台用户'),
  CLIENT_ADMIN: new Item('CLIENT_ADMIN', '用户端管理员'),
  CLIENT_COMMON: new Item('CLIENT_COMMON', '用户端普通人员'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(AccountTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _AccountTypeEnum$key = AccountTypeEnum[key],
          comment = _AccountTypeEnum$key.comment,
          deprecated = _AccountTypeEnum$key.deprecated,
          checked = _AccountTypeEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 标签理财产品活动类型枚举
 * @type {{COMMON: Item, GROUPBOOK: Item, SECKILL: Item ,HOTSELL:Item}}
 */

exports.AccountTypeEnum = AccountTypeEnum;
var ProductActivityTypeEnum = {
  COMMON: new Item('COMMON', '普通'),
  GROUPBOOK: new Item('GROUPBOOK', '拼团'),
  SECKILL: new Item('SECKILL', '秒杀'),

  /**
   * 这里是为了 @type 自动生成选项
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ProductActivityTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ProductActivityTypeE = ProductActivityTypeEnum[key],
          comment = _ProductActivityTypeE.comment,
          deprecated = _ProductActivityTypeE.deprecated,
          checked = _ProductActivityTypeE.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 产品类别枚举
 * @type {{FUND: Item,BANK_FINANCE:Item ,OUTSIDE_BORDERS_FINANCE:Item }}
 */

exports.ProductActivityTypeEnum = ProductActivityTypeEnum;
var ProductTypeEnum = {
  FUND: new Item('FUND', '基金'),
  BANK_FINANCE: new Item('BANK_FINANCE', '行内理财'),
  OUTSIDE_BORDERS_FINANCE: new Item('OUTSIDE_BORDERS_FINANCE', '境外理财'),
  LARGE_DEPOSITS: new Item('LARGE_DEPOSITS', '大额存款'),
  SMART_FINANCIAL_MANAGEMENT: new Item('SMART_FINANCIAL_MANAGEMENT', '智能存款'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ProductTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ProductTypeEnum$key = ProductTypeEnum[key],
          comment = _ProductTypeEnum$key.comment,
          deprecated = _ProductTypeEnum$key.deprecated,
          checked = _ProductTypeEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 产品类别枚举2
 * @type {{DEFERRAL: Item,FINANCIAL:Item}}
 */

exports.ProductTypeEnum = ProductTypeEnum;
var ProductTypeEnum2 = {
  DEFERRAL: new Item('DEFERRAL', '定存产品'),
  FINANCIAL: new Item('FINANCIAL', '理财产品'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ProductTypeEnum2).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ProductTypeEnum2$key = ProductTypeEnum2[key],
          comment = _ProductTypeEnum2$key.comment,
          deprecated = _ProductTypeEnum2$key.deprecated,
          checked = _ProductTypeEnum2$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 产品状态枚举
 * @type {{TOBERELEASED: Item,HAVERELEASED:Item,FINISHED:Item,SOLDOUT:Item}}
 */

exports.ProductTypeEnum2 = ProductTypeEnum2;
var ProductStatusEnum = {
  TOBERELEASED: new Item('TOBERELEASED', '待发布'),
  HAVERELEASED: new Item('HAVERELEASED', '已发布'),
  FINISHED: new Item('FINISHED', '已结束'),
  SOLDOUT: new Item('SOLDOUT', '已售罄'),

  /**
   * 构建枚举集合
   * @return {Item[]}
   */
  options: function options() {
    return Object.keys(ProductStatusEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ProductStatusEnum$ke = ProductStatusEnum[key],
          comment = _ProductStatusEnum$ke.comment,
          deprecated = _ProductStatusEnum$ke.deprecated,
          checked = _ProductStatusEnum$ke.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 分红方式枚举
 * @type {{BONUS_SWAP: Item,CASH_DIVIDENDS:Item}}
 */

exports.ProductStatusEnum = ProductStatusEnum;
var AllowDividendMethodEnum = {
  BONUS_SWAP: new Item('BONUS_SWAP', '红利转投'),
  CASH_DIVIDENDS: new Item('CASH_DIVIDENDS', '现金分红'),
  options: function options() {
    return Object.keys(AllowDividendMethodEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _AllowDividendMethodE = AllowDividendMethodEnum[key],
          comment = _AllowDividendMethodE.comment,
          deprecated = _AllowDividendMethodE.deprecated,
          checked = _AllowDividendMethodE.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 转换标识枚举
 * @type {{0: Item,1:Item}}
 */

exports.AllowDividendMethodEnum = AllowDividendMethodEnum;
var SwitchFlagEnum = {
  TRANSFERABLE: new Item('TRANSFERABLE', '可转入'),
  CANBETRANSFERREDOUT: new Item('CANBETRANSFERREDOUT', '可转出'),
  CANONLYBETRANSFERREDOUT: new Item('CANONLYBETRANSFERREDOUT', '只可转出'),
  NOT_TRANSFORM: new Item('NOT_TRANSFORM', '不可转换'),
  options: function options() {
    return Object.keys(SwitchFlagEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _SwitchFlagEnum$key = SwitchFlagEnum[key],
          comment = _SwitchFlagEnum$key.comment,
          deprecated = _SwitchFlagEnum$key.deprecated,
          checked = _SwitchFlagEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 赎回状态
 * @type {{TO_BEEX_ECUTED: Item,COMPLETED:Item REVOKED：Item UN_REDEEMED:Item}}
 */

exports.SwitchFlagEnum = SwitchFlagEnum;
var ToHoNorStatusEnum = {
  TO_BEEX_ECUTED: new Item('TO_BEEX_ECUTED', '待执行'),
  COMPLETED: new Item('COMPLETED', '已完成'),
  REVOKED: new Item('REVOKED', '已撤销'),
  UN_REDEEMED: new Item('UN_REDEEMED', '未赎回'),
  options: function options() {
    return Object.keys(ToHoNorStatusEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _ToHoNorStatusEnum$ke = ToHoNorStatusEnum[key],
          comment = _ToHoNorStatusEnum$ke.comment,
          deprecated = _ToHoNorStatusEnum$ke.deprecated,
          checked = _ToHoNorStatusEnum$ke.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 订单类型
 * @type {{SUBSIST: Item,HISTORY:Item }}
 */

exports.ToHoNorStatusEnum = ToHoNorStatusEnum;
var OrderTypeEnum = {
  SUBSIST: new Item('SUBSIST', '存续订单'),
  HISTORY: new Item('HISTORY', '历史订单'),
  options: function options() {
    return Object.keys(OrderTypeEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _OrderTypeEnum$key = OrderTypeEnum[key],
          comment = _OrderTypeEnum$key.comment,
          deprecated = _OrderTypeEnum$key.deprecated,
          checked = _OrderTypeEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
/**
 * 订单类型
 * @type {{SUBSIST: Item,HISTORY:Item SPELLGROUPSUCCESS：Item SPELLGROUPFAIL：Item }}
 */

exports.OrderTypeEnum = OrderTypeEnum;
var GroupStatusEnum = {
  NONE: new Item('NONE', '未知'),
  ACTIVITY: new Item('ACTIVITY', '进行中'),
  SPELLGROUPSUCCESS: new Item('SPELLGROUPSUCCESS', '拼团成功'),
  SPELLGROUPFAIL: new Item('SPELLGROUPFAIL', '拼团失败'),
  options: function options() {
    return Object.keys(GroupStatusEnum).filter(function (key) {
      return key !== 'options';
    }).map(function (key) {
      var _GroupStatusEnum$key = GroupStatusEnum[key],
          comment = _GroupStatusEnum$key.comment,
          deprecated = _GroupStatusEnum$key.deprecated,
          checked = _GroupStatusEnum$key.checked;
      return new Item(key, comment, {
        deprecated: deprecated,
        checked: checked
      });
    });
  }
};
exports.GroupStatusEnum = GroupStatusEnum;