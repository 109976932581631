"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.object.get-own-property-names.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.exportExcel = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/objectSpread2"));

var _defineProperty2 = _interopRequireDefault(require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/defineProperty"));

var _CustomerGroupManagementService = _interopRequireDefault(require("@/api/CustomerGroupManagementService"));

var _axios = _interopRequireDefault(require("axios"));

var _jsFileDownload = _interopRequireDefault(require("js-file-download"));

var _loginToken = require("@/utils/loginToken");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var exportExcel = function exportExcel(data) {
  var baseUrl = process.env.VUE_APP_BASE_API;
  return (0, _axios.default)({
    url: baseUrl + '/web/v2/auth/excelmgr/tplmde/download',
    method: 'post',
    data: data,
    'responseType': "arraybuffer",
    headers: {
      "Token": (0, _loginToken.getLoginToken)()
    }
  });
};

exports.exportExcel = exportExcel;
var _default2 = {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    },
    addType: {
      type: Number,
      required: false
    },
    isDetail: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    var _ref;

    return _ref = {
      btnSaveLoading: false,
      rules: {
        // 基本信息必填校验
        customerGroupName: [{
          required: true,
          message: "请输入客群名称",
          trigger: "blur"
        }],
        dataUpdateMode: [{
          required: true,
          message: '请选择更新方式',
          trigger: 'blur,change'
        }]
      },
      uploadFile: [],
      fileList: [],
      updateTypeArr: [],
      outConditionTypeOptions: [{
        dictLabel: '且',
        dictValue: 1
      }, {
        dictLabel: '或',
        dictValue: 2
      }],
      conditionTypeOptions: [],
      attrOptions: [],
      pdOptions: [],
      pdOptions1: [],
      pdOptions5: [{
        dictLabel: '绝对时间',
        dictValue: 1
      }, {
        dictLabel: '相对时间',
        dictValue: 2
      }],
      pdOptions6: [{
        dictLabel: '等于',
        dictValue: 1
      }, {
        dictLabel: '不等于',
        dictValue: 2
      }, {
        dictLabel: '正则表达',
        dictValue: 3
      }],
      sdOptions1: [{
        dictLabel: '男',
        dictValue: '0'
      }, {
        dictLabel: '女',
        dictValue: '1'
      }],
      sdOptions2: [{
        dictLabel: '等级1',
        dictValue: '1'
      }, {
        dictLabel: '等级2',
        dictValue: '2'
      }],
      sdOptions5: [{
        dictLabel: '最近7天',
        dictValue: '1'
      }, {
        dictLabel: '最近3天',
        dictValue: '2'
      }],
      sdOptions6: [{
        dictLabel: '机构1',
        dictValue: '1'
      }, {
        dictLabel: '机构2',
        dictValue: '2'
      }],
      tolCondition: 1,
      itemCondition: 1,
      attrList: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      outHeight: 50,
      innerHeight: 30
    }, (0, _defineProperty2.default)(_ref, "fileList", []), (0, _defineProperty2.default)(_ref, "selectRow", {}), (0, _defineProperty2.default)(_ref, "updatBtnLoading", false), _ref;
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this = this;

      this.$refs.rowForm.validate(function (valid, tipObj) {
        if (valid) {
          _this.btnSaveLoading = true;
          var attrList = JSON.parse(JSON.stringify(_this.attrList));

          if (attrList.length) {
            attrList.forEach(function (item) {
              if (attrList.dateValue) {
                delete attrList.dateValue;
              }

              if (item.valueOne) {
                item.valueOne = item.valueOne + '';
              }
            });
          } else {
            _this.$message.error("请配置用户属性！");

            return false;
          }

          var postObj = {
            customerProperties: attrList
          };
          var postParam = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this.selectRow), postObj);
          (_this.selectRow.id ? _CustomerGroupManagementService.default.update(postParam) : _CustomerGroupManagementService.default.save(postParam)).then(function (result) {
            result.hasFailure(function () {
              _this.btnSaveLoading = false;

              _this.$message.warning(result.getErrorMessage("保存失败"));
            }).hasSuccess(function () {
              _this.btnSaveLoading = false;

              _this.$message.success("保存成功");

              _this.handleCancel();

              _this.$emit("ok");
            });
          });
        } else {
          var ObjNameArr = Object.getOwnPropertyNames(tipObj);

          _this.$message.error(tipObj[ObjNameArr[0]][0].message);

          return false;
        }
      });
    },
    // 图片上传文件超出个数限制时
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u6700\u591A\u53EA\u80FD\u4E0A\u4F201\u5F20\u56FE\u7247\u54E6\uFF01");
    },
    beforeUpload: function beforeUpload(file) {
      this.file = file;
    },
    beforeRemove: function beforeRemove(file, fileList) {
      var uid = file.uid;
      var curIndex = 0;
      this.fileList.forEach(function (item, index) {
        if (item.uid == uid) {
          curIndex = index;
        }
      });
      this.fileList.splice(curIndex, 1);
    },
    importFun: function importFun() {
      var _this2 = this;

      var formData = new FormData();
      formData.append('file', this.file);

      _axios.default.post(process.env.VUE_APP_BASE_API + '/web/upload/excel/activity/white/list/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (res) {
        _this2.$message({
          message: '文件已上传，导入任务执行中',
          type: 'success'
        }); // this.selectedRow.secUUID = res.data.data[0]
        // this.selectedRow.fileName = this.file.name

      }).catch(function (err) {
        _this2.$message({
          message: '导入失败',
          type: 'error'
        });

        _this2.fileList = [];
      });
    },
    updateTypeChange: function updateTypeChange() {},
    addAttr: function addAttr() {
      this.attrList.push({});
      this.computeHeight();
    },
    dateChange: function dateChange(val, index) {
      if (!this.attrList[index].dateValue) {
        this.attrList[index].dateValue = [];
        this.attrList[index].valueOne = '';
        this.attrList[index].valueTwo = '';
      } else {
        this.attrList[index].valueOne = this.attrList[index].dateValue[0];
        this.attrList[index].valueTwo = this.attrList[index].dateValue[1];
      } // if (!this.dateValue) {
      //     this.dateValue = []
      //     this.queryParam.startTimeStr = ''
      //     this.queryParam.endTimeStr = ''
      // } else {
      //     this.queryParam.startTimeStr = val[0]
      //     this.queryParam.endTimeStr = val[1]
      // }

    },
    deleteAttr: function deleteAttr(index) {
      this.attrList.splice(index, 1);
      this.computeHeight();
    },
    // 重新计算内外下划线高度
    computeHeight: function computeHeight() {
      var len = this.attrList.length;

      if (len <= 2) {
        // this.outHeight = 50;
        this.innerHeight = 30;
      } else {
        // this.outHeight = 50 + ((len-2) * 40);
        this.innerHeight = 30 + (len - 2) * 40;
      }

      if (len <= 0) {
        this.outHeight = 50;
      } else if (len == 1) {
        this.outHeight = 50 + 55 / 2;
      } else {
        this.outHeight = 50 + 55 / 2 + (len - 1) * 40;
      }
    },
    exportExcelFun: function exportExcelFun() {
      var _this3 = this;

      var data = {
        //请求参数
        tplType: 'COUPON_IMPORT_TPL'
      };
      this.$confirm('确定要下载客群导入模板？', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        exportExcel(data, {
          responseType: 'blob'
        }).then(function (res) {
          //$api.exporPersontExcel或者api文件的接口
          (0, _jsFileDownload.default)(res.data, '客群导入模板.xlsx'); // this.$message({
          //   message: '成功！',
          //   type: 'success'
          // });
        });
      }).catch(function (err) {
        _this3.$message({
          message: '下载失败',
          type: 'error'
        });
      });
    },
    attrChange: function attrChange(index) {
      var curAttr = this.attrList[index];

      if (curAttr.relationalOperator) {
        this.attrList[index].relationalOperator = null;
      }

      if (curAttr.valueOne) {
        this.attrList[index].valueOne = null;
      }

      if (curAttr.valueTwo) {
        this.attrList[index].valueTwo = null;
      }
    },
    relationChange: function relationChange(index) {
      var curAttr = this.attrList[index];

      if (curAttr.valueOne) {
        this.attrList[index].valueOne = null;
      }

      if (curAttr.valueTwo) {
        this.attrList[index].valueTwo = null;
      }
    },
    getDetail: function getDetail() {
      var _this4 = this;

      var postParam = {
        id: this.row.id
      };

      _CustomerGroupManagementService.default.getCustomerGroupDetail(postParam).then(function (result) {
        _this4.selectRow = result.data[0];

        if (_this4.selectRow.customerProperties && _this4.selectRow.customerProperties.length) {
          _this4.attrList = _this4.selectRow.customerProperties;
        } else {
          _this4.attrList = [];
        } // if(result.data[0].picture && result.data[0].picture.videoUrl) {
        //   const picObj = {
        //     name: result.data[0].picture.fileName,
        //     secUuid: result.data[0].picture.secUuid
        //   }
        //   this.uploadFile = [picObj];
        //   this.selectRow.pictureUrl = result.data[0].secUuid;
        // }
        // if(result.data[0].cpncdFileUuid && result.data[0].cpncdFileName) {
        //   const fileObj = {
        //     name: result.data[0].cpncdFileName,
        //     secUuid: result.data[0].cpncdFileUuid
        //   }
        //   this.fileList = [fileObj];
        // }


        _this4.initData();
      });
    },
    initData: function initData() {
      if (this.addType || this.addType == 0) {
        this.selectRow.createMode = this.addType;
      }

      if (!(this.selectRow.status == 0 || this.selectRow.status)) {
        this.selectRow.status = 1;
      }

      if (!(this.selectRow.customerPropertiesLogicalOperator == 0 || this.selectRow.customerPropertiesLogicalOperator)) {
        this.selectRow.customerPropertiesLogicalOperator = 0;
      }
    },
    handleUpdateRow: function handleUpdateRow() {
      var _this5 = this;

      var postParam = {
        id: this.row.id
      };
      this.updatBtnLoading = true;

      _CustomerGroupManagementService.default.updateDetail(postParam).then(function (result) {
        _this5.updatBtnLoading = false;
        result.hasFailure(function (result) {
          return _this5.$message.error(result.getErrorMessage('更新失败'));
        }).hasSuccess(function () {
          _this5.$message.success('更新成功');
        });
      });
    }
  },
  created: function created() {
    var _this6 = this;

    this.selectRow = JSON.parse(JSON.stringify(this.row));

    if (this.row.id) {
      this.getDetail();
    } else {
      this.initData();
    } // 更新方式选项


    _CustomerGroupManagementService.default.dictListGet('CUSTOMER_GROUP_DATA_UPDATE_MODE').then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this6.updateTypeArr = result.data[0];
    }); // 用户属性逻辑选项


    _CustomerGroupManagementService.default.dictListGet('CUSTOMER_PROPERTIES_LOGICAL_OPERATOR').then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this6.conditionTypeOptions = result.data[0];
    }); // 用户属性选项


    _CustomerGroupManagementService.default.dictListGet('CUSTOMER_PROPERTIES').then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this6.attrOptions = result.data[0];
    }); // 用户属性逻辑判断选项


    _CustomerGroupManagementService.default.dictListGet('CUSTOMER_PROPERTIES_RELATIONAL_OPERATOR').then(function (result) {
      _this6.pdOptions1 = [];
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);

        if (item.dictValue == 0) {
          _this6.pdOptions1.push(item);
        }
      });
      _this6.pdOptions = result.data[0];
    });
  }
};
exports.default = _default2;