"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.includes.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _SysUserListService = _interopRequireDefault(require("@/api/SysUserListService"));

var _CommonService = _interopRequireDefault(require("@/api/CommonService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      avatar: null
    };
  },
  computed: (0, _objectSpread2.default)({
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  }, (0, _vuex.mapGetters)(['userId'])),
  methods: {
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      if (!['image/jpeg', 'image/png', 'image/x-icon'].includes(file.type)) {
        this.$message.error('上传头像图片只能是 jpg,png,ico 格式!');
        return false;
      }

      if (file.size / 1024 > 500) {
        this.$message.error('上传头像图片大小不能超过 500 KB !');
        return false;
      }

      return true;
    },
    handleUpload: function handleUpload(_ref) {
      var file = _ref.file,
          onSuccess = _ref.onSuccess,
          onError = _ref.onError;

      _CommonService.default.uploadUser(file).then(function (result) {
        return result.dataFirst(function (row) {
          return onSuccess(row);
        }, function () {
          return onError('文件上传成功，但服务器未返回文件信息');
        }).hasFailure(onError(result.getErrorMessage('文件上传失败')));
      });
    },
    handleAvatarSuccess: function handleAvatarSuccess(res) {
      this.avatar = res;
    },
    handleCancel: function handleCancel() {
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this = this;

      if (!this.avatar) {
        this.$message.error('请选择头像');
        return;
      }

      this.btnSaveLoading = true;
      var postData = {
        'avatar': this.avatar,
        'userId': this.userId
      };

      _SysUserListService.default.changeAvatar(postData).then(function (result) {
        _this.btnSaveLoading = false;
        result.hasFailure(function () {
          return _this.$message.warning(result.getErrorMessage('头像修改失败'));
        }).hasSuccess(function () {
          _this.$store.commit('user/SET_AVATAR', _this.avatar);

          _this.$message.success('头像修改成功');

          _this.handleCancel();

          _this.$emit('ok');
        });
      });
    }
  }
};
exports.default = _default;