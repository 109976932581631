"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.set.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/web.dom-collections.iterator.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _MenuConfigure = _interopRequireDefault(require("@/api/entity/MenuConfigure"));

var _MenuService = _interopRequireDefault(require("@/api/MenuService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: _MenuConfigure.default,
      required: true
    }
  },
  data: function data() {
    return {
      visibleTree: false,
      btnSaveLoading: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selectedCodes: new Set(),
      rules: {
        powerName: [{
          required: true,
          message: '请输入功能名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 100,
          message: '长度必须在 1-100 个字符',
          trigger: 'blur'
        }],
        parentPowerName: [{
          required: true,
          message: '请选择归属菜单',
          trigger: 'change'
        }],
        status: [{
          required: true,
          message: '请选择功能状态',
          trigger: 'change'
        }],
        powerCode: [{
          required: true,
          message: '请输入权限编号',
          trigger: 'blur'
        }]
      },
      treeData: [{
        label: '根节点',
        value: '0'
      }]
    };
  },
  computed: {
    visibleDrawer: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  created: function created() {},
  methods: {
    loadNode: function loadNode(node, resolve) {
      var _this = this;

      // if (node.level === 0) {
      //   return resolve([{ label: '根节点', value :'0'}]);
      // }
      setTimeout(function () {
        var params = {
          "powerId": node.data.powerId ? node.data.powerId : '0',
          "powerType": "menu"
        };

        _MenuService.default.getMenuTree(params).then(function (result) {
          var arrData = result.data.map(function (item) {
            item.value = item.powerId;
            item.label = item.powerName;
            item.isLeaf = true;
            return item;
          });
          result.hasFailure(function () {
            return _this.$message.error(result.message);
          });
          resolve(arrData);
        });
      }, 500);
    },
    handleOpen: function handleOpen() {// if (this.row.authorities) { // 编辑时填充权限树
      //   const refTree = this.$refs.tree
      //   // 过滤:只选择叶子节点, 父节点会自动选中
      //   const keys = this.row.authorities.filter(code => refTree.getNode(code)?.isLeaf)
      //   refTree.setCheckedKeys(keys)
      // }
    },
    handleChange: function handleChange(row) {
      this.row.parentPowerId = row.value;
      this.row.parentPowerName = row.label;
    },
    handleClose: function handleClose() {
      this.$refs.rowForm.resetFields(); // this.$refs.tree.setCheckedKeys([])

      this.visibleDrawer = false;
    },
    handleShow: function handleShow() {
      this.visibleTree = true;
    },
    handleCloseTree: function handleCloseTree() {
      this.visibleTree = false;
    },
    handleTree: function handleTree() {
      this.visibleTree = false;
    },
    handleSave: function handleSave() {
      var _this2 = this;

      this.$refs.rowForm.validate(function (valid) {
        if (valid) {
          var postdata = new _MenuConfigure.default(_this2.row);
          postdata.powerType = 'button';
          _this2.btnSaveLoading = true;
          (postdata.id ? _MenuService.default.update(postdata) : _MenuService.default.save(postdata)).then(function (result) {
            _this2.btnSaveLoading = false;
            result.hasFailure(function () {
              return _this2.$message.warning(result.getErrorMessage('保存失败'));
            }).hasSuccess(function () {
              _this2.$message.success('保存成功');

              _this2.handleClose();

              _this2.$emit('ok');
            });
          });
        }
      });
    }
  }
};
exports.default = _default;