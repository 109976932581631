"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _permission = _interopRequireDefault(require("@/directive/permission"));

var _Page = _interopRequireDefault(require("@/utils/entity/Page"));

var _BindCardRecordService = _interopRequireDefault(require("@/api/BindCardRecordService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  directives: {
    permission: _permission.default
  },
  data: function data() {
    return {
      queryParam: {
        phone: this.$route.query.phone,
        bindingType: null,
        pageNo: _Page.default.ofDefault().number,
        pageSize: _Page.default.ofDefault().size
      },
      list: null,
      listLoading: true,
      btnDeleteLoading: false,
      selectedRows: [],
      selectedRow: null,
      visibleSaveDialog: false,
      deleteIds: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateValue: [],
      options: [],
      sideOptions: [{
        label: '全部',
        value: null,
        key: 2
      }, {
        label: '已绑卡',
        value: 'BINDINGCARD',
        key: 0
      }, {
        label: '已解绑',
        value: 'UNBINDINGCARD',
        key: 1
      }],
      optionEnum: {
        0: '已绑卡',
        1: '已解绑'
      },
      couponData: [],
      btnSaveLoading: false,
      btnCtrlData: {
        batchDelete: false,
        delete: false,
        add: false,
        update: false,
        query: false,
        import: false,
        export: false,
        detail: false,
        limitconfig: false,
        link: false,
        menuput: false,
        powerput: false,
        enable: false,
        display: false,
        chanceconfig: false,
        levelconfig: false,
        okmanordisplay: false,
        bbwcarouse: false,
        homedisplay: false,
        sendticket: false,
        password: false
      }
    };
  },
  computed: {},
  watch: {
    '$root.menuData': {
      handler: function handler(val, old) {
        this.$getShowPageBtn(val, this.$route.meta.title, this.btnCtrlData);
      },
      deep: true
    },
    visibleSaveDialog: function visibleSaveDialog(value) {
      if (!value) {
        this.handleQuery();
      }
    }
  },
  created: function created() {
    this.handleQuery();

    if (this.$root.menuData && this.$root.menuData.length > 0) {
      this.$getShowPageBtn(this.$root.menuData, this.$route.meta.title, this.btnCtrlData);
    }
  },
  methods: {
    handleSave: function handleSave() {
      var _this = this;

      if (this.queryParam.totalCount == 0) {
        this.$message.warning('补发人数不能为0，请重新查询！');
        return false;
      }

      if (this.queryParam.couponBatchUuid == null) {
        this.$message.warning('请选择虚拟券！');
        return false;
      }

      this.btnSaveLoading = true;

      _BindCardRecordService.default.save(this.queryParam).then(function (result) {
        result.hasFailure(function () {
          _this.btnSaveLoading = false;

          _this.$message.warning(result.getErrorMessage('保存失败'));
        }).hasSuccess(function () {
          _this.btnSaveLoading = false;

          _this.$message.success('操作成功');

          _this.handleCancel();

          _this.handleQuery();
        });
      });
    },
    handleCancel: function handleCancel() {
      this.visibleSaveDialog = false;
    },
    dateChange: function dateChange(val) {
      // console.log("日期：");
      // console.log(this.dateValue);
      if (!this.dateValue) {
        this.dateValue = [];
        this.queryParam.qryBeginTime = '';
        this.queryParam.qryEndTime = '';
      } else {
        this.queryParam.qryBeginTime = val[0] + ' 00:00:00';
        this.queryParam.qryEndTime = val[1] + ' 23:59:59';
      }
    },
    handleQuery: function handleQuery() {
      var _this2 = this;

      this.listLoading = true;

      _BindCardRecordService.default.pageable(this.queryParam).then(function (result) {
        _this2.listLoading = false;
        _this2.list = result.data.map(function (row) {
          row.visible = false;

          if (row.bindingCardTime) {
            row.bindingCardTime = _this2.formatTime(row.bindingCardTime);
          } else {
            row.bindingCardTime = '';
          }

          if (row.unbindingCardTime) {
            row.unbindingCardTime = _this2.formatTime(row.unbindingCardTime);
          } else {
            row.unbindingCardTime = '';
          }

          return row;
        });
        _this2.queryParam.pageCount = result.pageCount;
        _this2.queryParam.totalCount = result.totalCount;
        result.hasFailure(function () {
          return _this2.$message.error(result.message);
        });
      });
    },
    // 时间格式化
    formatTime: function formatTime(t) {
      var data = t;
      var dtime = new Date(data);
      var year = dtime.getFullYear();
      var month = dtime.getMonth() + 1;

      if (month < 10) {
        month = '0' + month;
      }

      var day = dtime.getDate();

      if (day < 10) {
        day = '0' + day;
      }

      var hour = dtime.getHours();

      if (hour < 10) {
        hour = '0' + hour;
      }

      var minute = dtime.getMinutes();

      if (minute < 10) {
        minute = '0' + minute;
      }

      var second = dtime.getSeconds();

      if (second < 10) {
        second = '0' + second;
      }

      return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
    },
    handleDelete: function handleDelete(row) {
      var _this3 = this;

      this.listLoading = true;
      var postParam = {
        userId: row.userId,
        id: row.id
      };

      _BindCardRecordService.default.deleteRow(postParam).then(function (result) {
        _this3.listLoading = false;
        result.hasFailure(function (result) {
          return _this3.$message.error(result.getErrorMessage('删除失败'));
        }).hasSuccess(function () {
          _this3.$message.success('删除成功');

          _this3.handleQuery();
        });
      });
    }
  }
};
exports.default = _default;