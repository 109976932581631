"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/defineProperty"));

var _AdvanceTaskService = _interopRequireDefault(require("@/api/AdvanceTaskService"));

var _AdvanceTaskEditDialog = _interopRequireDefault(require("@/views/activityProduct/activityManager/components/AdvanceTaskEditDialog"));

var _ActivityManager = _interopRequireDefault(require("@/api/ActivityManager"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SaveDialog: _AdvanceTaskEditDialog.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    var _ref;

    return _ref = {
      selectedRow: {},
      btnSaveLoading: false,
      taskTypeOptions: [],
      isDetail: false,
      SaveDialog: false
    }, (0, _defineProperty2.default)(_ref, "isDetail", false), (0, _defineProperty2.default)(_ref, "list", null), (0, _defineProperty2.default)(_ref, "listLoading", true), (0, _defineProperty2.default)(_ref, "queryParam", {}), _ref;
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    },
    taskTypePreview: function taskTypePreview() {
      return function (val) {
        var result = '';
        this.taskTypeOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.selectedRow = {};
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this = this;

      var postParam = JSON.parse(JSON.stringify(this.selectedRow));

      if (postParam.cusGroupUuids) {
        postParam.cusGroupUuids = JSON.stringify(postParam.cusGroupUuids);
      }

      _ActivityManager.default.userSelEdit(postParam).then(function (result) {
        result.hasFailure(function () {
          _this.btnSaveLoading = false;

          _this.$message.warning(result.getErrorMessage('保存失败'));
        }).hasSuccess(function () {
          _this.btnSaveLoading = false;

          _this.$message.success('保存成功');

          _this.handleCancel();

          _this.$emit('ok');
        });
      });
    },
    handleQuery: function handleQuery() {
      var _this2 = this;

      this.listLoading = true;

      _AdvanceTaskService.default.advanceTaskList(this.queryParam).then(function (result) {
        _this2.listLoading = false;
        _this2.list = result.data.map(function (row) {
          row.visible = false;

          if (row.updateTime) {
            row.updateTimeStr = _this2.formatTime(row.updateTime);
          }

          return row;
        });
        _this2.queryParam.pageCount = result.pageCount;
        _this2.queryParam.totalCount = result.totalCount;
        result.hasFailure(function () {
          return _this2.$message.error(result.message);
        });
      });
    },
    handleDelete: function handleDelete(row) {
      var _this3 = this;

      this.listLoading = true;

      _AdvanceTaskService.default.deleteAddAdvanceTask({
        id: row.id
      }).then(function (result) {
        _this3.listLoading = false;
        result.hasFailure(function (result) {
          return _this3.$message.error(result.getErrorMessage('删除失败'));
        }).hasSuccess(function () {
          _this3.$message.success('删除成功');

          _this3.handleQuery();
        });
      });
    },
    handleDetail: function handleDetail(row) {
      this.isDetail = true;
      this.selectedRow = JSON.parse(JSON.stringify(row));
      this.SaveDialog = true;
    },
    handleUpdate: function handleUpdate(row) {
      this.isDetail = false;
      this.selectedRow = JSON.parse(JSON.stringify(row));
      this.SaveDialog = true;
    },
    // 时间格式化
    formatTime: function formatTime(t) {
      var data = t;
      var dtime = new Date(data);
      var year = dtime.getFullYear();
      var month = dtime.getMonth() + 1;

      if (month < 10) {
        month = '0' + month;
      }

      var day = dtime.getDate();

      if (day < 10) {
        day = '0' + day;
      }

      var hour = dtime.getHours();

      if (hour < 10) {
        hour = '0' + hour;
      }

      var minute = dtime.getMinutes();

      if (minute < 10) {
        minute = '0' + minute;
      }

      var second = dtime.getSeconds();

      if (second < 10) {
        second = '0' + second;
      }

      return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
    },
    handleAdd: function handleAdd() {
      this.isDetail = false;
      this.selectedRow = {
        id: undefined,
        activityUuid: this.queryParam.activityUuid
      };
      this.SaveDialog = true;
    }
  },
  created: function created() {
    var _this4 = this;

    _ActivityManager.default.dictListGet("SIGN_IN_ADVANCED_TASK_TYPE").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this4.taskTypeOptions = result.data[0];
    });

    this.queryParam.activityUuid = this.row.secUuid;
    this.handleQuery();
  }
};
exports.default = _default;