"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.asyncRoutes = exports.constantRoutes = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _layoutSec = _interopRequireDefault(require("@/layoutSec"));

var _Enums = require("@/api/Enums");

_vue.default.use(_vueRouter.default);
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */


var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login'));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}, {
  path: '/luckyPrize/save',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/luckyPrize/save'));
    });
  },
  hidden: true,
  meta: {
    noCache: true
  }
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard'));
      });
    },
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }]
} // {
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   meta: {
//     title: '活动管理',
//     icon: 'dashboard',
//     activeMenu: '/activityProduct/group',
//   },
//   children: [
//     {
//       path: 'activityManager',
//       name: 'activityManager',
//       component: () => import('@/views/activityProduct/activityManager/index.vue'),
//       meta: { title: '活动管理', icon: 'banner', roles: [AuthorityCode.Menu_Manage_User_List.key] }
//     }
//   ]
// },
];
exports.constantRoutes = constantRoutes;
var asyncRoutes = [// {
//   path: '/pointManagent',
//   name: 'pointManagent',
//   component: Layout,
//   redirect: '',
//   meta: { title: '埋点管理', icon: 'user', roles: [AuthorityCode.Menu_Banner.key] },
//   children: [
//     {
//       path: 'applicationManagent',
//       name: 'applicationManagent',
//       component: () => import('@/views/applicationManagent/index.vue'),
//       meta: { title: '应用管理', icon: 'banner', roles: [AuthorityCode.Menu_Banner.key] }
//     },
//     {
//       path: 'eventList',
//       name: 'eventList',
//       component: () => import('@/views/eventList/index.vue'),
//       meta: { title: '事件列表', icon: 'banner', roles: [AuthorityCode.Menu_Banner.key] }
//     },
//   ]
// },
{
  path: '/activityProduct',
  name: 'activityProduct',
  component: _layout.default,
  redirect: '/activityProduct/group',
  meta: {
    title: '活动管理',
    icon: 'gift',
    activeMenu: '/activityProduct/group',
    roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key, _Enums.AuthorityCode.Menu_Manage_Role_List.key, _Enums.AuthorityCode.Menu_User_Tag_List.key]
  },
  children: [{
    path: 'activityManager',
    name: 'activityManager',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activityProduct/activityManager/index.vue'));
      });
    },
    meta: {
      title: '活动管理',
      icon: 'banner',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }]
}, {
  path: '/giftManagement',
  name: 'giftManagement',
  component: _layout.default,
  redirect: '/giftManagement/index',
  meta: {
    title: '礼品管理',
    icon: 'gift',
    activeMenu: '/giftManagement/index',
    roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key, _Enums.AuthorityCode.Menu_Manage_Role_List.key, _Enums.AuthorityCode.Menu_User_Tag_List.key]
  },
  children: [{
    path: 'giftManagement',
    name: 'giftManagement',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/giftManagement/index'));
      });
    },
    meta: {
      title: '礼品管理',
      icon: 'gift',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, {
    path: 'cardList',
    name: 'CardList',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/giftManagement/cardList.vue'));
      });
    },
    meta: {
      title: '券码明细',
      icon: 'jilu',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, {
    path: 'giftClaimRecord',
    name: 'giftClaimRecord',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/giftManagement/claimRecord'));
      });
    },
    meta: {
      title: '礼品领取记录',
      icon: 'gift',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }]
}, {
  path: '/customerAnalysis',
  name: 'customerAnalysis',
  component: _layout.default,
  redirect: '/customerAnalysis/customerManagement',
  meta: {
    title: '客户分析',
    icon: 'tongji',
    activeMenu: '/customerAnalysis/customerManagement',
    roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key, _Enums.AuthorityCode.Menu_Manage_Role_List.key, _Enums.AuthorityCode.Menu_User_Tag_List.key]
  },
  children: [{
    path: 'customerGroupManagement',
    name: 'customerGroupManagement',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/customerAnalysis/customerGroupManagement/index'));
      });
    },
    meta: {
      title: '客群管理',
      icon: 'tongji',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  } // {
  //   path: 'tagManagement',
  //   name: 'tagManagement', // name 一定要跟组件名一样，默认的组件名是大驼峰命名
  //   component: () => import('@/views/customerAnalysis/tagManagement/index'),
  //   meta: { title: '标签管理', icon: 'tongji', roles: [AuthorityCode.Menu_Manage_User_List.key] }
  // },
  ]
}, {
  path: '/materialManagement',
  name: 'materialManagement',
  component: _layout.default,
  redirect: '/materialManagement/materialLibrary',
  meta: {
    title: '素材管理',
    icon: 'peoples',
    activeMenu: '/materialManagement/materialLibrary',
    roles: [_Enums.AuthorityCode.Menu_User_Tag_List.key]
  },
  children: [{
    path: 'materialLibrary',
    name: 'materialLibrary',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/materialManagement/materialLibrary/index.vue'));
      });
    },
    meta: {
      title: '素材库',
      icon: 'gift',
      roles: [_Enums.AuthorityCode.Menu_User_Tag_List.key]
    }
  }, {
    path: 'materialConfig',
    name: 'materialConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/materialManagement/materialConfig/index.vue'));
      });
    },
    meta: {
      title: '活动素材配置',
      icon: 'gift',
      roles: [_Enums.AuthorityCode.Menu_User_Tag_List.key]
    }
  }]
}, {
  path: '/activityManage',
  name: 'activityManage',
  component: _layout.default,
  redirect: '/activityManage/labelConfig',
  meta: {
    title: '活动配置项',
    icon: 'duihuan',
    activeMenu: '/activityManage/labelConfig',
    roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key, _Enums.AuthorityCode.Menu_Manage_Role_List.key, _Enums.AuthorityCode.Menu_User_Tag_List.key]
  },
  children: [{
    path: 'assistSetting',
    name: 'AssistSetting',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/assistSetting/index.vue'));
      });
    },
    meta: {
      title: '助力设置',
      icon: 'duihuan',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, {
    path: 'blackAndwhiteList',
    name: 'BlackAndwhiteList',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/inroll/blackAndwhiteList'));
      });
    },
    meta: {
      title: '黑白名单',
      icon: 'user',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, {
    path: 'whiteDetail',
    name: 'whiteDetail',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/inroll/whiteDetail'));
      });
    },
    meta: {
      title: '客户名单详情',
      icon: 'user',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, {
    path: 'detailsConfig',
    name: 'DetailsConfig',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/DetailsConfig/index.vue'));
      });
    },
    meta: {
      title: '详情页管理',
      icon: 'duihuan',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, {
    path: 'customerGradeConfig',
    name: 'CustomerGradeConfig',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/customerGradeConfig/index'));
      });
    },
    meta: {
      title: '客户等级配置',
      icon: 'user',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, {
    path: 'tipInformationManagement',
    name: 'TipInformationManagement',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/tipInformationManagement/index'));
      });
    },
    meta: {
      title: '提示信息管理',
      icon: 'user',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }]
}, {
  path: '/businessOperations',
  name: 'businessOperations',
  component: _layout.default,
  redirect: '/businessOperations/bulletinBoard',
  meta: {
    title: '业务分析',
    icon: 'tongji',
    activeMenu: '/businessOperations/bulletinBoard',
    roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key, _Enums.AuthorityCode.Menu_Manage_Role_List.key, _Enums.AuthorityCode.Menu_User_Tag_List.key]
  },
  children: [{
    path: 'bulletinBoard',
    name: 'bulletinBoard',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/businessOperations/bulletinBoard'));
      });
    },
    meta: {
      title: '活动看板',
      icon: 'tongji',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, {
    path: 'activityReport',
    name: 'activityReport',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/activityReport/index'));
      });
    },
    meta: {
      title: '活动报表',
      icon: 'tongji',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }]
}, {
  path: '/system',
  name: 'system',
  component: _layout.default,
  // redirect: '/system/list',
  redirect: '',
  meta: {
    title: '系统管理',
    icon: 'peoples',
    activeMenu: '/system/list',
    roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key, _Enums.AuthorityCode.Menu_Manage_Role_List.key, _Enums.AuthorityCode.Menu_User_Tag_List.key]
  },
  children: [{
    path: 'userlist',
    name: 'Userlist',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/userList'));
      });
    },
    meta: {
      title: '用户管理',
      icon: 'user',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, // {
  //   path: 'department',
  //   name: 'Department', // name 一定要跟组件名一样，默认的组件名是大驼峰命名
  //   component: () => import('@/views/system/department'),
  //   meta: { title: '部门管理', icon: 'tree', roles: [AuthorityCode.Menu_Manage_Role_List.key] }
  // },
  // {
  //   path: 'worker',
  //   name: 'Worker', // name 一定要跟组件名一样，默认的组件名是大驼峰命名
  //   component: () => import('@/views/system/worker'),
  //   hidden: true,
  //   meta: { title: '人员管理', icon: 'user', roles: [AuthorityCode.Menu_Manage_Role_List.key] }
  // },
  {
    path: 'role',
    name: 'Role',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/role'));
      });
    },
    meta: {
      title: '角色管理',
      icon: 'user',
      roles: [_Enums.AuthorityCode.Menu_Manage_Role_List.key]
    }
  }, {
    path: 'menu',
    name: 'Menu',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/menu'));
      });
    },
    meta: {
      title: '菜单管理',
      icon: 'tree',
      roles: [_Enums.AuthorityCode.Menu_Manage_Role_List.key]
    }
  }, {
    path: 'menuFun',
    name: 'MenuFun',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/menuFun'));
      });
    },
    meta: {
      title: '功能管理',
      icon: 'tree',
      roles: [_Enums.AuthorityCode.Menu_Manage_Role_List.key]
    }
  }, {
    path: 'whiteDetail',
    name: 'whiteDetail',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/inroll/whiteDetail'));
      });
    },
    meta: {
      title: '客户名单详情',
      icon: 'user',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, {
    path: 'bindCardRecord',
    name: 'BindCardRecord',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/bindCardRecord'));
      });
    },
    meta: {
      title: '绑卡记录查询',
      icon: 'user',
      roles: [_Enums.AuthorityCode.Menu_Manage_Role_List.key]
    }
  }, {
    path: 'operationLog',
    name: 'operationLog',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/operationLog'));
      });
    },
    meta: {
      title: '管理员解绑操作日志',
      icon: 'user',
      roles: [_Enums.AuthorityCode.Menu_Manage_Role_List.key]
    }
  }, {
    path: 'dictionaryManagent',
    name: 'dictionaryManagent',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/dictionaryManagent/index'));
      });
    },
    meta: {
      title: '字典管理',
      icon: 'tree',
      roles: [_Enums.AuthorityCode.Menu_Manage_Role_List.key]
    }
  }, {
    path: 'dictionaryDetail',
    name: 'dictionaryDetail',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/dictionaryManagent/dictionaryDetail'));
      });
    },
    meta: {
      title: '字典详情',
      icon: 'tree',
      roles: [_Enums.AuthorityCode.Menu_Manage_User_List.key]
    }
  }, {
    path: 'systemLog',
    name: 'systemLog',
    // name 一定要跟组件名一样，默认的组件名是大驼峰命名
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/systemLog/index'));
      });
    },
    meta: {
      title: '系统日志',
      icon: 'tree',
      roles: [_Enums.AuthorityCode.Menu_Manage_Role_List.key]
    }
  } // {
  //   path: 'scheduleTime',
  //   name: 'scheduleTime', // name 一定要跟组件名一样，默认的组件名是大驼峰命名
  //   component: () => import('@/views/system/scheduleTime'),
  //   meta: {title: '定时任务管理', icon: 'user', roles: [AuthorityCode.Menu_Manage_Role_List.key]}
  // },
  // {
  //   path: 'timerScheduleTime',
  //   name: 'timerScheduleTime', // name 一定要跟组件名一样，默认的组件名是大驼峰命名
  //   hidden: true,
  //   component: () => import('@/views/system/scheduleTime/timerScheduleTime'),
  //   meta: {title: '定时器管理', icon: 'user', roles: [AuthorityCode.Menu_Manage_Role_List.key]}
  // },
  // {
  //   path: 'scheduleTimeLog',
  //   name: 'scheduleTimeLog', // name 一定要跟组件名一样，默认的组件名是大驼峰命名
  //   hidden: true,
  //   component: () => import('@/views/system/scheduleTime/scheduleTimeLog'),
  //   meta: {title: '执行日志', icon: 'user', roles: [AuthorityCode.Menu_Manage_Role_List.key]}
  // },
  ]
}, // 这里有一个需要非常注意的地方就是 404 页面一定要最后加载，如果放在constantRouterMap一同声明了404，后面的所以页面都会被拦截到404
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    base: "/".concat(process.env.VUE_APP_PREFIX),
    mode: 'history',
    // 使用 history 模式时 nginx 需要配置   try_files $uri $uri/ /index.html;
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;