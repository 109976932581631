"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.set.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/web.dom-collections.iterator.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/defineProperty"));

var _SystemRole = _interopRequireDefault(require("@/api/entity/SystemRole"));

var _MenuService = _interopRequireDefault(require("@/api/MenuService"));

var _SystemRoleService = _interopRequireDefault(require("@/api/SystemRoleService"));

var _methods;

var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: _SystemRole.default,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      tree: null,
      props: {
        label: 'name',
        children: 'nodes'
      },
      selectedCodes: new Set(),
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      powerList: [],
      idsArr: [],
      totalData: []
    };
  },
  computed: {
    visibleDrawer: {
      get: function get() {
        if (this.visible == true) {
          this.loadAllNode();
        }

        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  created: function created() {},
  methods: (_methods = {
    handleOpen: function handleOpen() {},
    handleClose: function handleClose() {
      this.visibleDrawer = false;
    },
    handleSave: function handleSave() {
      var _this = this;

      this.powerList = this.$refs.refTree.getCheckedNodes(false, true);

      if (this.powerList.length > 0) {
        var afList = [];
        this.powerList.forEach(function (e) {
          var iObj = {
            id: e.id,
            powerId: e.powerId,
            powerCode: e.powerCode
          };
          afList.push(iObj);
        });
        var param = {
          roleId: this.row.roleId,
          id: this.row.id,
          powerList: afList
        };
        this.btnSaveLoading = true;

        _SystemRoleService.default.addPower(param).then(function (result) {
          _this.btnSaveLoading = false;
          result.hasFailure(function () {
            return _this.$message.warning(result.getErrorMessage('保存失败'));
          }).hasSuccess(function () {
            _this.$message.success('保存成功');

            _this.handleClose(); // this.$emit('ok')

          });
        });

        this.visibleDrawer = false;
      } else {
        this.$message.warning('请至少选择一项菜单');
        return;
      }
    },
    loadAllNode: function loadAllNode() {
      var _this2 = this;

      setTimeout(function () {
        var params = {
          "powerId": '0',
          "powerType": "",
          "roleId": _this2.row.roleId
        };

        _MenuService.default.getAllMenuTreeSec(params).then(function (result) {
          var arrData = result.data[0].map(function (item) {
            item.value = item.powerId;
            item.label = item.powerName;

            if (item.children.length > 0) {
              item.children = _this2.nextChildren(item.children);
              var parentCheck = true;

              for (var n = 0; n < item.children.length; n++) {
                if (item.children[n].checked == false) {
                  parentCheck = false;
                }
              }

              item.checked = parentCheck;
            } else {
              item.isLeaf = true;

              if (item.checked == true) {
                _this2.idsArr.push(item.id);
              }
            }

            return item;
          });
          _this2.treeData = arrData;

          _this2.$refs.refTree.setCheckedKeys(_this2.idsArr);

          result.hasFailure(function () {
            return _this2.$message.error(result.message);
          });
        });
      }, 500);
    },
    nextChildren: function nextChildren(children) {
      var _this3 = this;

      var data = children.map(function (item) {
        item.value = item.powerId;
        item.label = item.powerName;

        if (item.children.length > 0) {
          item.children = _this3.nextChildren(item.children);
          var parentCheck = true;

          for (var n = 0; n < item.children.length; n++) {
            if (item.children[n].checked == false) {
              parentCheck = false;
            }
          }

          item.checked = parentCheck;
        } else {
          item.isLeaf = true;

          if (item.checked == true) {
            _this3.idsArr.push(item.id);
          }
        }

        return item;
      });
      return data;
    }
  }, (0, _defineProperty2.default)(_methods, "handleOpen", function handleOpen() {// if (this.row.authorities) { // 编辑时填充权限树
    //   const refTree = this.$refs.tree
    //   // 过滤:只选择叶子节点, 父节点会自动选中
    //   const keys = this.row.authorities.filter(code => refTree.getNode(code)?.isLeaf)
    //   refTree.setCheckedKeys(keys)
    // }
  }), (0, _defineProperty2.default)(_methods, "handleChange", function handleChange(row) {
    this.row.parentPowerId = row.value;
    this.row.parentPowerName = row.label;
  }), (0, _defineProperty2.default)(_methods, "loadPowerNode", function loadPowerNode(node, resolve) {
    var _this4 = this;

    setTimeout(function () {
      if (node.level == 0) {
        var params = {
          "powerId": '0',
          "powerType": 'menu',
          "roleId": _this4.row.roleId
        };

        _MenuService.default.getMenuTreeSec(params).then(function (result) {
          var arrData = result.data.map(function (item) {
            item.value = item.powerId;
            item.label = item.powerName;
            item.isLeaf = true;

            if (item.checked == true) {
              _this4.idsArr.push(item.id);
            }

            _this4.totalData.push(item);

            return item;
          });
          result.hasFailure(function () {
            return _this4.$message.error(result.message);
          });
          resolve(arrData);

          _this4.$refs.refTree.setCheckedKeys(_this4.idsArr);
        });
      } else {
        var _params = {
          "powerId": node.data.powerId,
          "powerType": null,
          "roleId": _this4.row.roleId
        };

        _MenuService.default.getMenuTreeSec(_params).then(function (result) {
          var arrData = result.data.map(function (item) {
            item.value = item.powerId;
            item.label = item.powerName;
            item.isLeaf = true;

            if (item.checked == true) {
              _this4.idsArr.push(item.id);
            }

            _this4.totalData.push(item);

            return item;
          });
          result.hasFailure(function () {
            return _this4.$message.error(result.message);
          });
          resolve(arrData);

          _this4.$refs.refTree.setCheckedKeys(_this4.idsArr);
        });
      }
    }, 500);
  }), (0, _defineProperty2.default)(_methods, "handleCheckChange", function handleCheckChange(data, checked) {
    if (checked) {
      this.idsArr.push(data.id);
    } else {
      var arrIndex = 0;
      this.idsArr.forEach(function (s, i) {
        if (data.id == s) {
          arrIndex = i;
        }
      });
      this.idsArr.splice(arrIndex, 1);
    }
  }), _methods)
};
exports.default = _default;