"use strict";

require("core-js/modules/es.array.every.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.object.keys.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setParamPageNo = setParamPageNo;

function setParamPageNo(param) {
  var returnData;

  if (param && param.pageNo > 1) {
    var objectArr = Object.keys(param);

    if (objectArr.length >= 4) {
      var pageArr = ['pageCount', 'pageNo', 'pageSize', 'totalCount'];
      var queryIsNotAllNull = objectArr.every(function (item) {
        var result = false;

        if (pageArr.includes(item)) {
          result = true;
        } else {
          if (param[item] || param[item] == 0) {
            result = true;
          }
        }

        return result;
      });

      if (queryIsNotAllNull) {
        returnData = JSON.parse(JSON.stringify(param));
        returnData.pageNo = 1; // delete returnData.pageCount;
        // delete returnData.totalCount;
      } else {
        returnData = JSON.parse(JSON.stringify(param));
      }
    } else {
      returnData = JSON.parse(JSON.stringify(param));
    }
  } else {
    returnData = JSON.parse(JSON.stringify(param));
  }

  return returnData;
}