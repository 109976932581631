var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: _vm.row.id ? "编辑" : "新增",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "700px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "rowForm",
          staticStyle: { width: "500px", margin: "auto" },
          attrs: { model: _vm.row, rules: _vm.rules, "label-width": "140px" }
        },
        [
          _vm.row.childType === 1
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "素材名称", prop: "label" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", maxlength: "200" },
                        model: {
                          value: _vm.row.label,
                          callback: function($$v) {
                            _vm.$set(_vm.row, "label", $$v)
                          },
                          expression: "row.label"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述", prop: "comment" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "请输入描述" },
                        model: {
                          value: _vm.row.comment,
                          callback: function($$v) {
                            _vm.$set(_vm.row, "comment", $$v)
                          },
                          expression: "row.comment"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "素材类型", prop: "childType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { autocomplete: "off", disabled: _vm.disCtrl },
                          model: {
                            value: _vm.row.childType,
                            callback: function($$v) {
                              _vm.$set(_vm.row, "childType", $$v)
                            },
                            expression: "row.childType"
                          }
                        },
                        _vm._l(_vm.materialTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.dictValue,
                            attrs: {
                              label: item.dictLabel,
                              value: item.dictValue
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.row.childType === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "文本效果", prop: "parameter" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入文本效果"
                            },
                            model: {
                              value: _vm.row.parameter,
                              callback: function($$v) {
                                _vm.$set(_vm.row, "parameter", $$v)
                              },
                              expression: "row.parameter"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.row.childType === 1
                    ? _c("el-form-item", { attrs: { label: "" } }, [
                        _c("span", [
                          _vm._v(
                            "依次输入：字体、字重、颜色、字号、行高，以逗号隔开"
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.row.id
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "素材名称", prop: "label" } },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off", maxlength: "200" },
                            model: {
                              value: _vm.row.label,
                              callback: function($$v) {
                                _vm.$set(_vm.row, "label", $$v)
                              },
                              expression: "row.label"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.row.id
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "描述", prop: "comment" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入描述"
                            },
                            model: {
                              value: _vm.row.comment,
                              callback: function($$v) {
                                _vm.$set(_vm.row, "comment", $$v)
                              },
                              expression: "row.comment"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "素材类型", prop: "childType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { autocomplete: "off", disabled: _vm.disCtrl },
                          model: {
                            value: _vm.row.childType,
                            callback: function($$v) {
                              _vm.$set(_vm.row, "childType", $$v)
                            },
                            expression: "row.childType"
                          }
                        },
                        _vm._l(_vm.materialTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.dictValue,
                            attrs: {
                              label: item.dictLabel,
                              value: item.dictValue
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.row.childType === 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: "''",
                                limit: _vm.pictureNum,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "file-list": _vm.uploadFile,
                                "http-request": _vm.handleUploadPicture
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnSaveLoading },
              on: { click: _vm.handleSave }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }