"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ActivityManager = _interopRequireDefault(require("@/api/ActivityManager"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      rules: {},
      selectedRow: {},
      linkTypeOptions: [],
      linkZoneOptions: [{
        dictValue: '0',
        dictLabel: '全部区域'
      }, {
        dictValue: '1',
        dictLabel: '分区-长方形'
      }, {
        dictValue: '2',
        dictLabel: '分区-圆形'
      }],
      coordinateOptions: [{
        dictValue: '',
        dictLabel: '全部区域'
      }, {
        dictValue: '0',
        dictLabel: '分区-长方形'
      }, {
        dictValue: '1',
        dictLabel: '分区-圆形'
      }],
      editableTabsValue: '0',
      editableTabs: [],
      tabIndex: 0
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      // this.$refs.rowForm.resetFields()
      this.editableTabs = [];
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      this.btnSaveLoading = false;
      this.$message.success('保存成功');
      var backData = JSON.parse(JSON.stringify(this.row));
      backData.links = this.editableTabs;
      this.$emit('ok', backData);
      this.handleCancel();
    },
    addTab: function addTab(targetName) {
      this.tabIndex++;
      var newTabTitle = '链接' + this.tabIndex;
      var newTabName = 'tab' + this.tabIndex;
      this.editableTabs.push({
        title: newTabTitle,
        name: newTabName
      });
      this.editableTabsValue = newTabName;
    },
    removeTab: function removeTab(targetName) {
      var tabs = this.editableTabs;
      var activeName = this.editableTabsValue;

      if (activeName === targetName) {
        tabs.forEach(function (tab, index) {
          if (tab.name === targetName) {
            var nextTab = tabs[index + 1] || tabs[index - 1];

            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(function (tab) {
        return tab.name !== targetName;
      });
    }
  },
  created: function created() {
    var _this = this;

    // 转跳类型
    _ActivityManager.default.dictListGet('PICTURE_LINK_TYPE').then(function (result) {
      // result.data[0].forEach(item => {
      //     item.dictValue = Number(item.dictValue);
      // });
      _this.linkTypeOptions = result.data[0];
    });

    _ActivityManager.default.dictListGet('BANNER_LINK_JUMP_AREA_TYPE').then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this.linkZoneOptions = result.data[0];
    }); // 编辑反显


    if (this.row && this.row.links && this.row.links.length) {
      var preRow = JSON.parse(JSON.stringify(this.row));
      this.editableTabs = preRow.links.map(function (item) {
        _this.tabIndex++;
        var newTabTitle = '链接' + _this.tabIndex;
        var newTabName = 'tab' + _this.tabIndex;
        item.title = newTabTitle;
        item.name = newTabName; //   this.editableTabs.push({
        //   title: newTabTitle,
        //   name: newTabName,
        // });
        // this.editableTabsValue = newTabName;

        return item;
      });
      this.editableTabsValue = this.editableTabs[0].name; // this.editableTabs.forEach((item,index)=>{
      //   // 
      //   this.tabIndex ++;
      //   let newTabTitle = '链接' + this.tabIndex;
      //   let newTabName = 'tab' + this.tabIndex;
      //   this.editableTabs.push({
      //     title: newTabTitle,
      //     name: newTabName,
      //   });
      //   this.editableTabsValue = newTabName;
      // })
    } else {
      this.editableTabs = [];
    }
  }
};
exports.default = _default;