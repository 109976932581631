"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.number.constructor.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _convertImgUrlPrefix2 = _interopRequireDefault(require("@/utils/convertImgUrlPrefix"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    },
    type: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      selectedRow: {}
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit("update:visible", val);
      }
    },
    convertImgUrlPrefix: function convertImgUrlPrefix() {
      return function (url) {
        return (0, _convertImgUrlPrefix2.default)(url);
      };
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.visibleDialog = false;
    }
  },
  created: function created() {}
};
exports.default = _default;