"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SystemRole = _interopRequireDefault(require("@/api/entity/SystemRole"));

var _Page = _interopRequireDefault(require("@/utils/entity/Page"));

var _permission = _interopRequireDefault(require("@/directive/permission"));

var _MarkDelete = _interopRequireDefault(require("@/utils/entity/MarkDelete"));

var _RoleSaveDrawer = _interopRequireDefault(require("@/views/system/role/components/RoleSaveDrawer"));

var _RoleDetailDrawer = _interopRequireDefault(require("@/views/system/role/components/RoleDetailDrawer"));

var _selectMenuDrawer = _interopRequireDefault(require("@/views/system/role/components/selectMenuDrawer"));

var _selectPowerDrawer = _interopRequireDefault(require("@/views/system/role/components/selectPowerDrawer"));

var _SystemRoleService = _interopRequireDefault(require("@/api/SystemRoleService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    RoleDetailDrawer: _RoleDetailDrawer.default,
    RoleSaveDrawer: _RoleSaveDrawer.default,
    SelectMenuDrawer: _selectMenuDrawer.default,
    SelectPowerDrawer: _selectPowerDrawer.default
  },
  // components: {RoleDetailDrawer, RoleSaveDrawer,SelectMenuDrawer},
  directives: {
    permission: _permission.default
  },
  data: function data() {
    return {
      queryParam: {
        "pageNo": _Page.default.ofDefault().number,
        "pageSize": _Page.default.ofDefault().size
      },
      list: null,
      listLoading: true,
      btnDeleteLoading: false,
      // selectedRows: new Role(),
      selectedRow: new _SystemRole.default(),
      visibleDetailDrawer: false,
      visibleSaveDrawer: false,
      visibleMenuDrawer: false,
      visiblePowerDrawer: false,
      addSendData: null,
      qxOptions: [{
        name: '本人',
        value: 'SELF'
      }, {
        name: '本分舵',
        value: 'RUDDER'
      }, {
        name: '全部',
        value: 'ALL'
      }],
      menuTimer: '1',
      powerTimer: '',
      btnCtrlData: {
        batchDelete: false,
        delete: false,
        add: false,
        update: false,
        query: false,
        import: false,
        export: false,
        detail: false,
        limitconfig: false,
        link: false,
        menuput: false,
        powerput: false,
        enable: false,
        display: false,
        chanceconfig: false,
        levelconfig: false,
        okmanordisplay: false,
        bbwcarouse: false,
        homedisplay: false,
        sendticket: false,
        password: false
      }
    };
  },
  computed: {// translatePower(type){
    //   let result = '';
    //   this.qxOptions.map(t=>{
    //     if(t.value == type){
    //       result = t.name
    //       return
    //     }
    //     return result
    //   })
    //   return result
    // }
  },
  created: function created() {
    this.handleQuery();

    if (this.$root.menuData && this.$root.menuData.length > 0) {
      this.$getShowPageBtn(this.$root.menuData, this.$route.meta.title, this.btnCtrlData);
    }
  },
  methods: {
    handleQuery: function handleQuery() {
      var _this = this;

      this.listLoading = true;
      this.selectedRow = new _SystemRole.default({
        "id": 0
      });

      _SystemRoleService.default.pageable(this.queryParam).then(function (result) {
        _this.listLoading = false;
        _this.list = result.data.map(function (row) {
          row.visible = false;
          var dataPowerName = '';

          _this.qxOptions.map(function (t) {
            if (t.value == row.dataPowerType) {
              dataPowerName = t.name;
              return;
            }
          });

          row.dataPowerName = dataPowerName;
          return row;
        });
        _this.queryParam.pageCount = result.pageCount;
        _this.queryParam.totalCount = result.totalCount;
        result.hasFailure(function () {
          return _this.$message.error(result.message);
        });
      });
    },
    handleSelected: function handleSelected(row) {
      this.selectedRow = new _SystemRole.default(row); // this.selectedRow = row
    },
    handleAdd: function handleAdd() {
      this.selectedRow = new _SystemRole.default();
      this.visibleSaveDrawer = true;
    },
    handleUpdate: function handleUpdate(row) {
      this.selectedRow = new _SystemRole.default(row);
      this.visibleSaveDrawer = true;
    },
    handleDetail: function handleDetail(row) {
      this.selectedRow = new _SystemRole.default(row);
      this.visibleDetailDrawer = true;
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;

      this.listLoading = true;
      var postParam = {
        id: row.id
      };

      _SystemRoleService.default.markDeleteByUuid(postParam).then(function (result) {
        _this2.listLoading = false;
        result.hasFailure(function (result) {
          return _this2.$message.error(result.getErrorMessage('删除失败'));
        }).hasSuccess(function () {
          _this2.$message.success('删除成功');

          _this2.handleQuery();
        });
      });
    },
    handleBatchDelete: function handleBatchDelete() {
      if (this.selectedRow && this.selectedRow.id) {
        this.btnDeleteLoading = true;
        this.menuTimer = new Date().getTime();
        this.visibleMenuDrawer = true;
        this.btnDeleteLoading = false;
      } else {
        this.$message.warning('请至少选择一行数据');
        return;
      } // this.$confirm('是否将菜单分配给改角色?', '警告', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // // }).then(async() => {
      // }).then(async() => {
      //   this.btnDeleteLoading = true
      //   this.visibleMenuDrawer =true
      //   this.btnDeleteLoading = false
      //   // const addPowerParam = {
      //   //   id: this.selectedRows.id,
      //   //   roleId: this.selectedRows.roleId
      //   // }
      //   // RoleService.addPower(addPowerParam)
      //   //   .then(result => {
      //   //     this.btnDeleteLoading = false
      //   //     result
      //   //       .hasFailure(result => this.$message.error(result.message))
      //   //       .hasSuccess(() => {
      //   //         this.$message.success('分配成功')
      //   //         this.handleQuery()
      //   //       })
      //   //   })
      //   })

    },
    handleAddPower: function handleAddPower(row) {
      // this.btnDeleteLoading = true
      this.selectedRow = row;
      this.powerTimer = new Date().getTime();
      this.visiblePowerDrawer = true;
      this.btnDeleteLoading = false; // if (this.selectedRow && this.selectedRow.id) {
      //   this.btnDeleteLoading = true
      //   this.powerTimer = new Date().getTime()
      //   this.visiblePowerDrawer = true
      //   this.btnDeleteLoading = false
      // } else {
      //   this.$message.warning('请至少选择一行数据')
      //   return
      // }
    }
  },
  watch: {
    '$root.menuData': {
      handler: function handler(val, old) {
        this.$getShowPageBtn(val, this.$route.meta.title, this.btnCtrlData);
      },
      deep: true
    }
  }
};
exports.default = _default;