var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.bodyoLading,
          expression: "bodyoLading"
        }
      ],
      staticClass: "app-container",
      attrs: {
        "element-loading-text": "海报生成中，请稍等",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)"
      }
    },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.queryParam }
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_ADMIN", "Redemption_save"],
                      expression: "['ROLE_ADMIN', 'Redemption_save']"
                    }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAdd }
                },
                [_vm._v(" + 新增")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入活动名称" },
                    model: {
                      value: _vm.queryParam.activityName,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParam, "activityName", $$v)
                      },
                      expression: "queryParam.activityName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择活动类型" },
                      model: {
                        value: _vm.queryParam.activityType,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParam, "activityType", $$v)
                        },
                        expression: "queryParam.activityType"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "全部", value: "" } }),
                      _vm._l(_vm.activityTypeOptions, function(item) {
                        return _c("el-option", {
                          key: item.dictValue,
                          attrs: {
                            label: item.dictLabel,
                            value: item.dictValue
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择活动状态" },
                      model: {
                        value: _vm.queryParam.isncFlg,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParam, "isncFlg", $$v)
                        },
                        expression: "queryParam.isncFlg"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "全部", value: "" } }),
                      _vm._l(_vm.isncFlgOption, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_ADMIN", "Redemption_load"],
                          expression: "['ROLE_ADMIN', 'Redemption_load']"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "cell-style": { "text-align": "center" },
            "header-cell-style": { "text-align": "center" },
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", width: "50" }
          }),
          _c("el-table-column", {
            attrs: { label: "活动名称", prop: "activityName" }
          }),
          _c("el-table-column", {
            attrs: { label: "活动类型", prop: "activityType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.activityTypePreview(scope.row.activityType)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "描述", prop: "activityDescribe" }
          }),
          _c("el-table-column", {
            attrs: { label: "活动状态", prop: "isncFlg" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.isncFlg ? "上架" : "下架"))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "创建用户", prop: "createUser" }
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间", prop: "createTime" }
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "150",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    !row.isncFlg
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "0" },
                            attrs: { size: "mini", type: "text", circle: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleActivityTime(row)
                              }
                            }
                          },
                          [_vm._v("上架")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "0" },
                            attrs: { size: "mini", type: "text", circle: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleActivityTime(row)
                              }
                            }
                          },
                          [_vm._v("下架")]
                        ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "0" },
                        attrs: { size: "mini", type: "text", circle: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row, $index)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    !row.isncFlg
                      ? [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                "confirm-button-text": "好的",
                                "cancel-button-text": "取消",
                                icon: "el-icon-info",
                                "icon-color": "red",
                                title: "您确认要删除这条数据吗?"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleDelete(row)
                                }
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "text-red",
                                  attrs: {
                                    slot: "reference",
                                    size: "mini",
                                    type: "text",
                                    circle: ""
                                  },
                                  slot: "reference"
                                },
                                [_vm._v(" 删除 ")]
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "0" },
                        attrs: { size: "mini", type: "text", circle: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleCustomer(row)
                          }
                        }
                      },
                      [_vm._v("客群设置")]
                    ),
                    _c(
                      "el-dropdown",
                      {
                        attrs: { trigger: "click" },
                        on: { command: _vm.handleMore }
                      },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _vm._v(" 更多操作... ")
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            row.activityType == 35
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.beforeCommand(row, 1)
                                    }
                                  },
                                  [_vm._v("进阶任务设置")]
                                )
                              : _vm._e(),
                            row.activityType == 26
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.beforeCommand(row, 4)
                                    }
                                  },
                                  [_vm._v("广播功能设置")]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: _vm.beforeCommand(row, 3) } },
                              [_vm._v("活动时间设置")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: _vm.beforeCommand(row, 2) } },
                              [_vm._v("详情")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.queryParam.pageNo,
              "page-size": _vm.queryParam.pageSize,
              total: _vm.queryParam.totalCount,
              "page-sizes": [10, 20, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:total": function($event) {
                return _vm.$set(_vm.queryParam, "totalCount", $event)
              },
              "size-change": function($event) {
                return _vm.handleQuery("page")
              },
              "current-change": function($event) {
                return _vm.handleQuery("page")
              }
            }
          })
        ],
        1
      ),
      _c("SaveDialog", {
        ref: "SaveDialogRef",
        attrs: {
          visibleSaveDialog: _vm.visibleSaveDialog,
          activityTypeOptions: _vm.activityTypeOptions,
          activityCode: _vm.activityCode,
          isDetail: _vm.isDetail
        },
        on: { closeVisible: _vm.closeVisible }
      }),
      _vm.detailsDrawer
        ? _c("DetailsDialog", {
            attrs: { visible: _vm.detailsDrawer, detailsList: _vm.detailsList },
            on: {
              handleCloseDetail: function($event) {
                _vm.detailsDrawer = false
              }
            }
          })
        : _vm._e(),
      _vm.selectActPopCtrl
        ? _c(
            "el-dialog",
            {
              attrs: { width: "500px", visible: _vm.selectActPopCtrl },
              on: {
                "update:visible": function($event) {
                  _vm.selectActPopCtrl = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  staticStyle: { width: "400px", margin: "auto" },
                  attrs: { "label-width": "100px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择活动" },
                          model: {
                            value: _vm.activityCode,
                            callback: function($$v) {
                              _vm.activityCode = $$v
                            },
                            expression: "activityCode"
                          }
                        },
                        _vm._l(_vm.activityTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.dictValue,
                            attrs: {
                              label: item.dictLabel,
                              value: item.dictValue
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.selectActPopCtrl = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSaveActType }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.customerDialog
        ? _c("CustomerDialog", {
            attrs: { row: _vm.selectRow, visible: _vm.customerDialog },
            on: {
              "update:visible": function($event) {
                _vm.customerDialog = $event
              },
              ok: _vm.handleQuery
            }
          })
        : _vm._e(),
      _vm.activityTimeDialog
        ? _c("ActivityTimeDialog", {
            attrs: {
              row: _vm.selectRow,
              visible: _vm.activityTimeDialog,
              type: _vm.activityTimeDialogType
            },
            on: {
              "update:visible": function($event) {
                _vm.activityTimeDialog = $event
              },
              ok: function($event) {
                return _vm.handleQuery("update")
              }
            }
          })
        : _vm._e(),
      _vm.advanceTaskDialog
        ? _c("AdvanceTaskDialog", {
            attrs: { row: _vm.selectRow, visible: _vm.advanceTaskDialog },
            on: {
              "update:visible": function($event) {
                _vm.advanceTaskDialog = $event
              },
              ok: _vm.handleQuery
            }
          })
        : _vm._e(),
      _vm.broadcastDialog
        ? _c("BroadcastFunctionDialog", {
            attrs: { row: _vm.selectRow, visible: _vm.broadcastDialog },
            on: {
              "update:visible": function($event) {
                _vm.broadcastDialog = $event
              },
              ok: function($event) {
                return _vm.handleQuery("update")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }