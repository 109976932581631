"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _Logo = _interopRequireDefault(require("./Logo"));

var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));

var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));

var _navBarService = _interopRequireDefault(require("@/api/navBarService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      resultRoutes: null
    };
  },
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  mounted: function mounted() {
    this.getMenuData();
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['routes', 'sidebar'])), {}, {
    // routes() {
    //   return this.$router.options.routes
    // },
    activeMenu: function activeMenu() {
      // :default-active="$route.path"
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  methods: {
    getMenuData: function getMenuData() {
      var _this = this;

      var _self = this; // import {getLoginToken} from '@/utils/loginToken'


      _navBarService.default.options().then(function (result) {
        var resultRoutes = [];
        var bfRoutes = _this.routes;
        _self.$root.menuData = result.data[0];
        bfRoutes.forEach(function (item) {
          var ipath = item.path;

          if (ipath == "/404" || ipath == "/redirect" || ipath == "/login" || ipath == "/luckyPrize/save" || ipath == "/" || ipath == '*') {
            resultRoutes.push(item);
          } else {
            var resultData = result.data[0];

            for (var i = 0; i < resultData.length; i++) {
              if (resultData[i].powerName == item.meta.title) {
                if (resultData[i].children && resultData[i].children.length > 0 && item.children && item.children.length > 0) {
                  var childArr = _this.thoroughObj(item.children, resultData[i].children);

                  item.children = childArr;
                  resultRoutes.push(item);
                } else {
                  item.children = [];
                  resultRoutes.push(item);
                }
              } // if(resultData[i].children && resultData[i].children.length > 0 && item.children && item.children.length > 0){
              //   var iobj = item
              //   iobj.children = null
              //   iobj.children = this.doObj(item.children,resultData[i].children)
              //   resultRoutes.push(iobj)
              // }else{
              //   if(resultData[i].powerName == item.meta.title){
              //     resultRoutes.push(item)
              //   }
              // }

            }
          }
        });
        _self.resultRoutes = resultRoutes; // this.listLoading = false

        result.hasFailure(function () {
          return _this.$message.error(result.message);
        });
      });
    },
    // doObj(bf,re) {
    //   let arr = []
    //   bf.forEach(b=>{
    //     re.forEach(r=>{
    //       if(r.powerName == b.meta.title){
    //         if(r.children && r.children.length > 0 && b.children && b.children.length > 0){
    //           return this.doObj(b.children,r.children)
    //         }else{
    //           arr.push(b)
    //           return arr
    //         }
    //       }
    //     });
    //   })
    // },
    thoroughObj: function thoroughObj(bf, res) {
      var _this2 = this;

      var rtArr = [];
      bf.forEach(function (b) {
        for (var i = 0; i < res.length; i++) {
          if (res[i].powerName == b.meta.title) {
            if (res[i].children && res[i].children.length > 0 && b.children && b.children.length > 0) {
              var childArr = _this2.thoroughObj(b.children, res[i].children);

              b.children = childArr;
              rtArr.push(b);
            } else {
              b.children = [];
              rtArr.push(b);
            }
          }
        }
      });
      return rtArr;
    }
  }
};
exports.default = _default;