"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ActivityManager = _interopRequireDefault(require("@/api/ActivityManager"));

var _RightsNumber = _interopRequireDefault(require("@/api/RightsNumber"));

var _convertImgUrlPrefix2 = _interopRequireDefault(require("@/utils/convertImgUrlPrefix"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    detailsList: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      activityTypeOptions: [],
      collectTypeOptions: [],
      collectDemandTypeOptions: [],
      integrateTypeOptions: [],
      highTaskTypeOptions: [],
      compareTypeOptions: [],
      cardLayoutOptions: [],
      taskPositionOptions: [],
      collaUUIDOptions: [],
      expandTypeOptions: [],
      rightsTypeOptions: [],
      receiveMethodOptions: [],
      rightsNumberOptions: [],
      taskTypeOptions: [],
      // 任务型活动浏览页面
      browsePageOptions: [{
        dictLabel: '活动主页面',
        dictValue: '0'
      }],
      // 活动推荐
      sales: []
    };
  },
  methods: {
    handleCloseDetail: function handleCloseDetail() {
      this.$emit("handleCloseDetail");
    },
    // 查询活动推荐详情
    getRecommendDetail: function getRecommendDetail(secUuid) {
      var _this = this;

      var postParam = {
        activityUuid: secUuid
      };

      _ActivityManager.default.recommendGet(postParam).then(function (result) {
        if (result.data.length) {
          _this.sales = result.data;
          _this.displayStyle = result.data[0].displayStyle;
        }

        _this.initBrowsePageOptions();
      });
    },
    // 初始化浏览页面下拉选项
    initBrowsePageOptions: function initBrowsePageOptions() {
      var _this2 = this;

      var browseArr = [];

      if (this.sales.length > 0) {
        this.sales.forEach(function (item) {
          if (item.recommendName && item.detailBanner && item.detailBanner.bannerId) {
            browseArr.push({
              dictLabel: item.recommendName,
              dictValue: item.id + ''
            });
          }
        });

        if (browseArr.length > 0) {
          this.browsePageOptions = browseArr;
          var browseObj = {
            dictLabel: '活动主页面',
            dictValue: '0'
          };
          this.browsePageOptions.unshift(browseObj);
        }
      } else {
        this.browsePageOptions = browseArr;
        var _browseObj = {
          dictLabel: '活动主页面',
          dictValue: '0'
        };
        this.browsePageOptions.unshift(_browseObj);
      }

      if (this.detailsList.allTaskVos && this.detailsList.allTaskVos.length > 0) {
        this.detailsList.allTaskVos.forEach(function (item, index) {
          if (item.taskType && item.taskType === 3) {
            var browsePageName = '';

            _this2.browsePageOptions.map(function (bm) {
              if (item.browsePage == bm.dictValue) {
                browsePageName = bm.dictLabel;
                return;
              }
            }); // item.browsePageName = browsePageName


            _this2.$set(_this2.detailsList.allTaskVos[index], 'browsePageName', browsePageName);
          }
        });
      }
    }
  },
  mounted: function mounted() {// this.getRecommendDetail(this.detailsList.secUuid)
  },
  created: function created() {
    var _this3 = this;

    // 活动类型
    _ActivityManager.default.dictListGet('ACTIVITY_TYPE').then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.activityTypeOptions = result.data[0];
    }); // 集卡方式


    _ActivityManager.default.dictListGet('COLLECT_TYPE').then(function (result) {
      // console.log(result);
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.collectTypeOptions = result.data[0];
    }); // 集卡要求


    _ActivityManager.default.dictListGet('COLLECT_DEMAND_TYPE').then(function (result) {
      // console.log(result);
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.collectDemandTypeOptions = result.data[0];
    }); // 集卡权益-积分类型


    _ActivityManager.default.dictListGet('INTEGRATE_TYPE').then(function (result) {
      // console.log(result);
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.integrateTypeOptions = result.data[0];
    }); // 集卡-高阶任务类型


    _ActivityManager.default.dictListGet('HIGH_TASK_TYPE').then(function (result) {
      // console.log(result);
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.highTaskTypeOptions = result.data[0];
    }); // 集卡-比较参数


    _ActivityManager.default.dictListGet('COMPARE_PARAM').then(function (result) {
      // console.log(result);
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.compareTypeOptions = result.data[0];
    }); // 基础卡片布局数据字典


    _ActivityManager.default.dictListGet("COLLECT_CARD_LAYOUT").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.cardLayoutOptions = result.data[0];
    }); // 进阶任务位置数据字典


    _ActivityManager.default.dictListGet("COLLECT_TASK_POSITION").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.taskPositionOptions = result.data[0];
    }); // 膨胀方式


    _ActivityManager.default.dictListGet('EXPAND_TYPE').then(function (result) {
      // console.log(result);
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.expandTypeOptions = result.data[0];
    }); // 助力设置


    _RightsNumber.default.collaCfgListGet().then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = item.secUuid;
        item.dictLabel = item.collaName;
      });
      _this3.collaUUIDOptions = result.data[0];
    }); // 权益类型


    _ActivityManager.default.dictListGet('RIGHTS_TYPE').then(function (result) {
      // console.log(result);
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.rightsTypeOptions = result.data[0];
    }); // 领取方式


    _ActivityManager.default.dictListGet('RECEIVE_METHOD').then(function (result) {
      // console.log(result);
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.receiveMethodOptions = result.data[0];
    }); // 权益次数


    _ActivityManager.default.dictListGet('RIGHTS_NUMBER').then(function (result) {
      // console.log(result);
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.rightsNumberOptions = result.data[0];
    }); // 任务型活动类型


    _ActivityManager.default.dictListGet('TASK_ACTIVITY_TYPE').then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.taskTypeOptions = result.data[0];
    });

    this.getRecommendDetail(this.detailsList.secUuid);
  },
  computed: {
    convertImgUrlPrefix: function convertImgUrlPrefix() {
      return function (url) {
        return (0, _convertImgUrlPrefix2.default)(url);
      };
    },
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    },
    activityTypePreview: function activityTypePreview() {
      return function (val) {
        var result = '';
        this.activityTypeOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    collectCardTypePreview: function collectCardTypePreview() {
      return function (val) {
        var result = '';
        this.collectTypeOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    cardRequirementsPreview: function cardRequirementsPreview() {
      return function (val) {
        var result = '';
        this.collectDemandTypeOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    expandTypePreview: function expandTypePreview() {
      return function (val) {
        var result = '';
        this.expandTypeOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    rightsTypePreview: function rightsTypePreview() {
      return function (val) {
        var result = '';
        this.rightsTypeOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    collaUuidPreview: function collaUuidPreview() {
      return function (val) {
        var result = '';
        this.collaUUIDOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    receiveMethodPreview: function receiveMethodPreview() {
      return function (val) {
        var result = '';
        this.receiveMethodOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    rightsNumberPreview: function rightsNumberPreview() {
      return function (val) {
        var result = '';
        this.rightsNumberOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    taskTypePreview: function taskTypePreview() {
      return function (val) {
        var result = '';
        this.highTaskTypeOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    startStatePreview: function startStatePreview() {
      return function (val) {
        var result = '';
        this.compareTypeOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    taskBrowseTypePreview: function taskBrowseTypePreview() {
      return function (val) {
        var result = '';
        this.taskTypeOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    },
    browsePagePreview: function browsePagePreview() {
      return function (val) {
        var result = '';
        this.browsePageOptions.map(function (item) {
          if (item.dictValue == val) {
            result = item.dictLabel;
          }
        });
        return result;
      };
    }
  }
};
exports.default = _default;