"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/web.url.js");

require("core-js/modules/web.url.to-json.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.setResponseInterceptor = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _isNull = _interopRequireDefault(require("lodash/isNull"));

var _isUndefined = _interopRequireDefault(require("lodash/isUndefined"));

var _isObject = _interopRequireDefault(require("lodash/isObject"));

var _isArray = _interopRequireDefault(require("lodash/isArray"));

var _isFunction = _interopRequireDefault(require("lodash/isFunction"));

var _pickBy = _interopRequireDefault(require("lodash/pickBy"));

var _loginToken = require("@/utils/loginToken");

/**
 * 封装 http 请求
 * node 环境 axios cookie 保持 ：
 * https://www.npmjs.com/package/axios-cookiejar-support
 * https://blog.csdn.net/sdfgjhdgh/article/details/105205238
 *
 * @author 谢长春 2019-7-28
 */
// import JSONBIG from 'json-bigint'
// axios.defaults.transformResponse=[
//   function(data){
//     const json = JSONBIG({
//       storeAsstring: true
//     })
//     const res = json.parse(data)
//     return res
//   }
// ]
var buildSearchParams = function buildSearchParams(params) {
  // url 带参如果包含对象，需要经过转换，变成字符串，否则 axios 会出错
  if (params) {
    var searchParams = new URLSearchParams();

    var arrayParam = function arrayParam(arrs, parent) {
      if (arrs.length === 0) return;
      if ((0, _isFunction.default)(arrs[0])) return;

      if ((0, _isArray.default)(arrs[0])) {
        arrs.forEach(function (item, index) {
          return arrayParam(item, "".concat(parent, "[").concat(index, "]"));
        });
      } else if ((0, _isObject.default)(arrs[0])) {
        arrs.forEach(function (item, index) {
          return objectParam(item, "".concat(parent, "[").concat(index, "]"));
        });
      } else {
        searchParams.append(parent, arrs.join(','));
      }
    };

    var objectParam = function objectParam(obj, parent) {
      var parameters = (0, _pickBy.default)(obj, function (value) {
        return !(value === '' || (0, _isNull.default)(value) || (0, _isUndefined.default)(value) || (0, _isFunction.default)(value));
      });
      Object.keys(parameters).forEach(function (key) {
        var path = parent ? "".concat(parent, ".").concat(key) : key;
        var value = parameters[key];

        if ((0, _isArray.default)(value)) {
          arrayParam(value, path);
        } else if ((0, _isObject.default)(value)) {
          objectParam(value, path);
        } else {
          searchParams.append(path, value);
        }
      });
    };

    objectParam(JSON.parse(JSON.stringify(params)));
    return searchParams.toString(); // console.log(qs.stringify({sorts: [OrderBy.desc('id')], arrs: ['a', 'b']}, {arrayFormat: 'repeat', allowDots: true}));
    // return qs.stringify(parameters, {arrayFormat: 'repeat', allowDots: true});
  }

  return '';
}; // console.log('tokenData:',tokenCookie,process.env.GULPFILE,process.env)


var http = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // 请求前缀
  withCredentials: process.env.NODE_ENV === 'development',
  // 允许跨域，否则开发环境每次刷新页面都会路由到登录页
  // headers: {'X-VERSION': process.env.VUE_APP_VERSION,'Token': tokenCookie}, // 前端版本号
  headers: {
    'X-VERSION': process.env.VUE_APP_VERSION
  },
  // 前端版本号
  paramsSerializer: buildSearchParams
});

if (process.env.GULPFILE) {
  // gulp 环境保持 cookie , 支持 csrf
  var tough = require('tough-cookie');

  var Cookie = tough.Cookie;
  var cookies = {};
  http.interceptors.request.use(function (config) {
    config.headers.cookie = Object.keys(cookies).map(function (key) {
      return "".concat(key, "=").concat(cookies[key]);
    }).join('; ');

    if (cookies['XSRF-TOKEN']) {
      config.headers = Object.assign(config.headers, {
        'X-XSRF-TOKEN': cookies['XSRF-TOKEN']
      });
    } else if (cookies['X-XSRF-TOKEN']) {
      config.headers = Object.assign(config.headers, {
        'X-XSRF-TOKEN': cookies['X-XSRF-TOKEN']
      });
    } else if (cookies['CSRF-TOKEN']) {
      config.headers = Object.assign(config.headers, {
        'X-CSRF-TOKEN': cookies['CSRF-TOKEN']
      });
    } else if (cookies['X-CSRF-TOKEN']) {
      config.headers = Object.assign(config.headers, {
        'X-CSRF-TOKEN': cookies['X-CSRF-TOKEN']
      });
    } // console.log('gulpToken:',tokenCookie)
    // if(tokenCookie){
    //   config.headers = Object.assign(config.headers, {'Token': tokenCookie})
    // }
    // console.log('gulpHeader:',config.headers)


    return config;
  });
  http.interceptors.response.use(function (response) {
    if (response.headers['set-cookie'] instanceof Array) {
      response.headers['set-cookie'].forEach(function (c) {
        var obj = Cookie.parse(c).toJSON();
        cookies[obj.key] = obj.value;
      });
    }

    return response;
  });
}
/**
 * 开发环境 http 请求配置
 */


if (process.env.NODE_ENV === 'development') {
  http.interceptors.request.use(function (config) {
    // request 拦截器，拦截请求参数，打印日志
    // if (process.env.NODE_ENV === 'development') {
    //   console.log(config)
    // }
    var method = config.method,
        baseURL = config.baseURL,
        url = config.url,
        params = config.params,
        data = config.data; // if(tokenCookie){
    //   config.headers = Object.assign(config.headers, {'Token': tokenCookie})
    // }
    // 打印链接、地址、参数
    // if (method.toUpperCase() === 'GET') {
    //   const searchParams = buildSearchParams(params)
    //   console.log(JSON.stringify([`${method.toUpperCase()} ${baseURL}${url}${searchParams ? `?${searchParams}` : ''}`, params]))
    // } else if (((config.headers || {})['content-type'] || '').startsWith('multipart/form-data;')) {
    //   console.log(JSON.stringify([`${method.toUpperCase()} ${baseURL}${url}`]))
    // }else {
    //   console.log(JSON.stringify([`${method.toUpperCase()} ${baseURL}${url}`, data]))
    // }

    return config;
  });
} // 全部环境


http.interceptors.request.use(function (config) {
  // request 拦截器，拦截请求参数，打印日志
  var method = config.method,
      baseURL = config.baseURL,
      url = config.url,
      params = config.params,
      data = config.data;
  var tokenCookie = (0, _loginToken.getLoginToken)();

  if (tokenCookie) {
    config.headers = Object.assign(config.headers, {
      'Token': tokenCookie
    });
  }

  return config;
}); // 响应拦截
// http.interceptors.response.use(
//   response => {
//     const {code} = response.data
//     if (code === 'A00003') {
//     } else {
//       return response
//     }
//   }
// )

/**
 * 设置响应拦截器
 * @param interceptor {function}
 */

var setResponseInterceptor = function setResponseInterceptor() {
  var interceptor = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (response) {};
  http.interceptors.response.use(interceptor);
};

exports.setResponseInterceptor = setResponseInterceptor;
var _default = http;
exports.default = _default;