var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: _vm.row.id ? "编辑" : "新增",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "900px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "rowForm",
          attrs: {
            model: _vm.selectRow,
            rules: _vm.rules,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "礼品名称", prop: "giftName" } },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  maxlength: "200",
                  disabled: _vm.isDetail
                },
                model: {
                  value: _vm.selectRow.giftName,
                  callback: function($$v) {
                    _vm.$set(_vm.selectRow, "giftName", $$v)
                  },
                  expression: "selectRow.giftName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "描述", prop: "giftDescribe" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入描述信息",
                  disabled: _vm.isDetail
                },
                model: {
                  value: _vm.selectRow.giftDescribe,
                  callback: function($$v) {
                    _vm.$set(_vm.selectRow, "giftDescribe", $$v)
                  },
                  expression: "selectRow.giftDescribe"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { flex: "1" },
                  attrs: { label: "礼品类型", prop: "giftType" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: true },
                      model: {
                        value: _vm.selectRow.giftType,
                        callback: function($$v) {
                          _vm.$set(_vm.selectRow, "giftType", $$v)
                        },
                        expression: "selectRow.giftType"
                      }
                    },
                    _vm._l(_vm.giftTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.dictValue,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.giftType == 1
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "渠道来源", prop: "channelSource" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: _vm.isDetail },
                          model: {
                            value: _vm.selectRow.channelSource,
                            callback: function($$v) {
                              _vm.$set(_vm.selectRow, "channelSource", $$v)
                            },
                            expression: "selectRow.channelSource"
                          }
                        },
                        _vm._l(_vm.channelTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.dictValue,
                            attrs: {
                              label: item.dictLabel,
                              value: item.dictValue
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.giftType == 3
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "系统展示名称", prop: "showName" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "217px" },
                        attrs: { autocomplete: "off", disabled: _vm.isDetail },
                        model: {
                          value: _vm.selectRow.showName,
                          callback: function($$v) {
                            _vm.$set(_vm.selectRow, "showName", $$v)
                          },
                          expression: "selectRow.showName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.giftType == 1
            ? _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "券码导入", prop: "cpncdFileUuid" }
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        attrs: { type: "text" },
                        model: {
                          value: _vm.selectRow.cpncdFileUuid,
                          callback: function($$v) {
                            _vm.$set(_vm.selectRow, "cpncdFileUuid", $$v)
                          },
                          expression: "selectRow.cpncdFileUuid"
                        }
                      }),
                      _c(
                        "span",
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: "",
                                "show-file-list": true,
                                limit: 1,
                                accept: ".xlsx",
                                "file-list": _vm.fileList,
                                "before-upload": _vm.beforeUpload,
                                "http-request": _vm.importFun,
                                "before-remove": _vm.beforeFileRemove,
                                disabled: _vm.isDetail
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    disabled: _vm.isDetail
                                  }
                                },
                                [_vm._v("上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "礼品图片" }
                    },
                    [
                      _vm.isDetail
                        ? _c(
                            "div",
                            [
                              _vm.selectRow.picture &&
                              _vm.selectRow.picture.videoUrl
                                ? _c("el-image", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100%",
                                      "min-height": "36px"
                                    },
                                    attrs: {
                                      src: _vm.selectRow.picture.videoUrl.url,
                                      fit: "scale-down"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: "''",
                                limit: 1,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "file-list": _vm.uploadFile,
                                "http-request": _vm.handleUploadPicture
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("上传")]
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.giftType == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "礼品单价", prop: "giftUnitPrice" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "217px" },
                    attrs: {
                      autocomplete: "off",
                      maxlength: "200",
                      disabled: _vm.isDetail
                    },
                    model: {
                      value: _vm.selectRow.giftUnitPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.selectRow, "giftUnitPrice", _vm._n($$v))
                      },
                      expression: "selectRow.giftUnitPrice"
                    }
                  }),
                  _c("span", { staticStyle: { "margin-left": "8px" } }, [
                    _vm._v("元")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.giftType == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "兑换说明", prop: "exchangeInstructions" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入兑换说明",
                      disabled: _vm.isDetail
                    },
                    model: {
                      value: _vm.selectRow.exchangeInstructions,
                      callback: function($$v) {
                        _vm.$set(_vm.selectRow, "exchangeInstructions", $$v)
                      },
                      expression: "selectRow.exchangeInstructions"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.giftType == 2 || _vm.giftType == 3
            ? _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _vm.giftType == 2
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "积分类型", prop: "integralType" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: _vm.isDetail },
                              model: {
                                value: _vm.selectRow.integralType,
                                callback: function($$v) {
                                  _vm.$set(_vm.selectRow, "integralType", $$v)
                                },
                                expression: "selectRow.integralType"
                              }
                            },
                            _vm._l(_vm.pointTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.giftType == 3
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "渠道来源", prop: "channelSource" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: _vm.isDetail },
                              model: {
                                value: _vm.selectRow.channelSource,
                                callback: function($$v) {
                                  _vm.$set(_vm.selectRow, "channelSource", $$v)
                                },
                                expression: "selectRow.channelSource"
                              }
                            },
                            _vm._l(_vm.channelTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "礼品图片" }
                    },
                    [
                      _vm.isDetail
                        ? _c(
                            "div",
                            [
                              _vm.selectRow.picture &&
                              _vm.selectRow.picture.videoUrl
                                ? _c("el-image", {
                                    staticStyle: {
                                      width: "80px",
                                      height: "100%",
                                      "min-height": "36px"
                                    },
                                    attrs: {
                                      src: _vm.selectRow.picture.videoUrl.url,
                                      fit: "scale-down"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: "''",
                                limit: 1,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "file-list": _vm.uploadFile,
                                "http-request": _vm.handleUploadPicture
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("上传")]
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.giftType == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "积分", prop: "integral" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "217px" },
                    attrs: {
                      autocomplete: "off",
                      maxlength: "200",
                      disabled: _vm.isDetail
                    },
                    model: {
                      value: _vm.selectRow.integral,
                      callback: function($$v) {
                        _vm.$set(_vm.selectRow, "integral", _vm._n($$v))
                      },
                      expression: "selectRow.integral"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.giftType == 3
            ? _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "活动批次号", prop: "activityBatchNo" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "217px" },
                        attrs: {
                          autocomplete: "off",
                          maxlength: "200",
                          disabled: _vm.isDetail
                        },
                        model: {
                          value: _vm.selectRow.activityBatchNo,
                          callback: function($$v) {
                            _vm.$set(_vm.selectRow, "activityBatchNo", $$v)
                          },
                          expression: "selectRow.activityBatchNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "礼品编号", prop: "giftCode" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "217px" },
                        attrs: {
                          autocomplete: "off",
                          maxlength: "200",
                          disabled: _vm.isDetail
                        },
                        model: {
                          value: _vm.selectRow.giftCode,
                          callback: function($$v) {
                            _vm.$set(_vm.selectRow, "giftCode", $$v)
                          },
                          expression: "selectRow.giftCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.giftType == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "库存", prop: "inventory" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "217px" },
                    attrs: {
                      autocomplete: "off",
                      maxlength: "200",
                      disabled: _vm.isDetail
                    },
                    model: {
                      value: _vm.selectRow.inventory,
                      callback: function($$v) {
                        _vm.$set(_vm.selectRow, "inventory", _vm._n($$v))
                      },
                      expression: "selectRow.inventory"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.giftType == 3
            ? _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "库存", prop: "inventory" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "217px" },
                        attrs: {
                          autocomplete: "off",
                          maxlength: "200",
                          disabled: _vm.isDetail
                        },
                        model: {
                          value: _vm.selectRow.inventory,
                          callback: function($$v) {
                            _vm.$set(_vm.selectRow, "inventory", _vm._n($$v))
                          },
                          expression: "selectRow.inventory"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "单价", prop: "giftUnitPrice" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "217px" },
                        attrs: {
                          autocomplete: "off",
                          maxlength: "200",
                          disabled: _vm.isDetail
                        },
                        model: {
                          value: _vm.selectRow.giftUnitPrice,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.selectRow,
                              "giftUnitPrice",
                              _vm._n($$v)
                            )
                          },
                          expression: "selectRow.giftUnitPrice"
                        }
                      }),
                      _c("span", { staticStyle: { "margin-left": "8px" } }, [
                        _vm._v("元")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.giftType == 3
            ? _c(
                "el-form-item",
                { attrs: { label: "链接地址", prop: "linkUrl" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入商品详细地址",
                      disabled: _vm.isDetail
                    },
                    model: {
                      value: _vm.selectRow.linkUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.selectRow, "linkUrl", $$v)
                      },
                      expression: "selectRow.linkUrl"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isDetail
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("关闭")
              ])
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleSave }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }