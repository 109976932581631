"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SysUserList = _interopRequireDefault(require("@/api/entity/SysUserList"));

var _convertImageUrl2 = _interopRequireDefault(require("@/utils/convertImageUrl"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: _SysUserList.default,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    visibleDrawer: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    },
    convertImageUrl: function convertImageUrl() {
      return function (url) {
        return (0, _convertImageUrl2.default)(url);
      };
    }
  },
  methods: {
    handleClose: function handleClose() {
      this.visibleDrawer = false;
    }
  }
};
exports.default = _default;