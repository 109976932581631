"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _MaterialManagementService = _interopRequireDefault(require("@/api/MaterialManagementService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    },
    materialTypeCode: {
      type: Number,
      required: false
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      roles: [],
      rules: {// taskTitle: [
        //   { required: true, message: "请输入任务名称", trigger: "blur" },
        // ],
        // taskCode: [
        //   { required: true, message: "请输入任务编码", trigger: "blur" },
        // ],
        // taskType: [
        //   { required: true, message: "请选择任务类型", trigger: "change" },
        // ],
        // executeClass: [
        //   { required: true, message: "请输入执行类全名", trigger: "blur" },
        // ],
        // status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      // 素材类型
      materialTypeOptions: [],
      //   statusArr: [
      //     { value: "ENABLE", title: "启用" },
      //     { value: "DISABLE", title: "停用" },
      //   ],
      disCtrl: true,
      uploadFile: [],
      pictureNum: 100
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this = this;

      this.$refs.rowForm.validate(function (valid) {
        if (valid) {
          _this.btnSaveLoading = true; // childType为0是图片 1是文本

          if (_this.row.childType === 0) {
            if (_this.uploadFile.length) {
              (_this.row.id ? _MaterialManagementService.default.updatePicture(_this.row, _this.uploadFile) : _MaterialManagementService.default.addUploadTemp(_this.uploadFile)).then(function (result) {
                result.hasFailure(function () {
                  _this.btnSaveLoading = false;

                  _this.$message.warning(result.getErrorMessage("保存失败"));
                }).hasSuccess(function () {
                  _this.btnSaveLoading = false;

                  _this.$message.success("保存成功");

                  _this.handleCancel();

                  _this.$emit("ok");
                });
              });
            }
          } else {
            (_this.row.id ? _MaterialManagementService.default.updateText(_this.row) : _MaterialManagementService.default.saveText(_this.row)).then(function (result) {
              result.hasFailure(function () {
                _this.btnSaveLoading = false;

                _this.$message.warning(result.getErrorMessage("保存失败"));
              }).hasSuccess(function () {
                _this.btnSaveLoading = false;

                _this.$message.success("保存成功");

                _this.handleCancel();

                _this.$emit("ok");
              });
            });
          }
        } else {
          return false;
        }
      });
    },
    // 时间格式化
    formatTime: function formatTime(t) {
      var data = t;
      var dtime = new Date(data);
      var year = dtime.getFullYear();
      var month = dtime.getMonth() + 1;

      if (month < 10) {
        month = "0" + month;
      }

      var day = dtime.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      return year + "-" + month + "-" + day;
    },
    // 活动规则图片上传文件超出个数限制时
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u6700\u591A\u53EA\u80FD\u4E0A\u4F20".concat(this.pictureNum, "\u5F20\u56FE\u7247\u54E6\uFF01"));
    },
    handleUploadPicture: function handleUploadPicture(_ref) {
      var file = _ref.file;
      this.uploadFile.push(file);
    },
    beforeRemove: function beforeRemove(file, fileList) {
      var uid = file.uid;
      var curIndex = 0;
      this.uploadFile.forEach(function (item, index) {
        if (item.uid == uid) {
          curIndex = index;
        }
      });
      this.uploadFile.splice(curIndex, 1);
    }
  },
  created: function created() {
    var _this2 = this;

    // childType 为0代表图片 为1代表文本
    this.row.childType = this.materialTypeCode;

    _MaterialManagementService.default.dictListGet("ACTIVITY_MATERIAL_TYPE").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this2.materialTypeOptions = result.data[0];
    }); // 图片反显


    if (this.row.id && this.row.videoUrl) {
      this.uploadFile.push(this.row.videoUrl);
    } // 设置字体默认


    if (!this.row.parameter) {
      // this.row.parameter = 'PingFang SC,normal,#333333,16px,16px'
      this.$set(this.row, 'parameter', 'PingFang SC,normal,#333333,16px,16px');
    } // 编辑时数量控制一个


    if (this.row.id) {
      this.pictureNum = 1;
    }
  }
};
exports.default = _default;