var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: "广播功能设置",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "900px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "rowForm",
          attrs: {
            model: _vm.selectedRow,
            rules: _vm.rules,
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "广播功能", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.selectedRow.status,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "status", $$v)
                    },
                    expression: "selectedRow.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("禁用")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "广播内容", prop: "gbnr" } },
            [
              _c("el-input", {
                staticStyle: { width: "230px" },
                attrs: {
                  placeholder: "请输入祝贺词",
                  disabled: _vm.selectedRow.status != 1
                },
                model: {
                  value: _vm.selectedRow.congratulations,
                  callback: function($$v) {
                    _vm.$set(_vm.selectedRow, "congratulations", $$v)
                  },
                  expression: "selectedRow.congratulations"
                }
              }),
              _c(
                "div",
                { staticClass: "itemLine" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "230px" },
                      attrs: {
                        placeholder: "请选择客户信息类型",
                        disabled: _vm.selectedRow.status != 1
                      },
                      model: {
                        value: _vm.selectedRow.cusInfoType,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedRow, "cusInfoType", $$v)
                        },
                        expression: "selectedRow.cusInfoType"
                      }
                    },
                    _vm._l(_vm.informationTypeOptions, function(typeItem) {
                      return _c("el-option", {
                        key: typeItem.dictValue,
                        attrs: {
                          label: typeItem.dictLabel,
                          value: typeItem.dictValue
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.selectedRow.status == 1
                ? _c(
                    "div",
                    { staticClass: "itemLine" },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: "''",
                            limit: 1,
                            "before-remove": _vm.beforeRemove,
                            "on-exceed": _vm.handleExceed,
                            "http-request": _vm.handleUploadPicture,
                            "file-list": _vm.ruleFile
                          }
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v("话术文件上传")
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip"
                            },
                            [_vm._v("支持文件格式：txt")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "itemLine" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.selectedRow.status != 1
                          }
                        },
                        [_vm._v("话术文件上传")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [_vm._v("支持文件格式：txt")]
                      ),
                      this.ruleFile.length
                        ? _c("div", { staticClass: "uploadName" }, [
                            _vm._v(_vm._s(this.ruleFile[0].name))
                          ])
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户信息脱敏", prop: "cusInfoMask" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.selectedRow.status != 1 },
                  model: {
                    value: _vm.selectedRow.cusInfoMask,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "cusInfoMask", $$v)
                    },
                    expression: "selectedRow.cusInfoMask"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "播放时长", prop: "playDuration" } },
            [
              _c("el-input", {
                staticStyle: { width: "230px" },
                attrs: {
                  placeholder: "请输入广播播放时长",
                  disabled: _vm.selectedRow.status != 1
                },
                model: {
                  value: _vm.selectedRow.playDuration,
                  callback: function($$v) {
                    _vm.$set(_vm.selectedRow, "playDuration", _vm._n($$v))
                  },
                  expression: "selectedRow.playDuration"
                }
              }),
              _c("span", { staticClass: "unitTxt" }, [_vm._v("秒")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "播放间隔", prop: "playInterval" } },
            [
              _c("el-input", {
                staticStyle: { width: "230px" },
                attrs: {
                  placeholder: "请输入播放间隔",
                  disabled: _vm.selectedRow.status != 1
                },
                model: {
                  value: _vm.selectedRow.playInterval,
                  callback: function($$v) {
                    _vm.$set(_vm.selectedRow, "playInterval", _vm._n($$v))
                  },
                  expression: "selectedRow.playInterval"
                }
              }),
              _c("span", { staticClass: "unitTxt" }, [_vm._v("毫秒")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "播放顺序", prop: "playOrder" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择播放顺序",
                    disabled: _vm.selectedRow.status != 1
                  },
                  model: {
                    value: _vm.selectedRow.playOrder,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "playOrder", $$v)
                    },
                    expression: "selectedRow.playOrder"
                  }
                },
                _vm._l(_vm.playOrderOptions, function(item) {
                  return _c("el-option", {
                    key: item.dictValue,
                    attrs: { label: item.dictLabel, value: item.dictValue }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnSaveLoading },
              on: { click: _vm.handleSave }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }